import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsServiceService } from 'src/app/service/news-service.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-shop-left-sidebar-page-two',
    templateUrl: './shop-left-sidebar-page-two.component.html',
    styleUrls: ['./shop-left-sidebar-page-two.component.scss']
})
export class ShopLeftSidebarPageTwoComponent implements OnInit {
    constructor(private route: ActivatedRoute,private newsService:NewsServiceService) { }

    newsId :any;
    selectedItem:any;
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.newsId = params['newsId']; 
            console.log("newsId "+this.newsId);
            this.newsService.getNewsDetailsById(this.newsId).subscribe(res=>{
                this.selectedItem = res;
               
            })

          //  const selectedItem = this.itemDetails.getDetails(this.itemId);
           
           
          });
    }

    saveImageChanges(){
        const uploadFormData = new FormData();
        uploadFormData.append('file', this.selectedFileVehicelReg);
        let details = {
            'id':this.newsId,
        
        }
        uploadFormData.append('details', JSON.stringify(details));

        this.newsService.editImage(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Successfully editted the news image!',
                  }).then((result) => {
                    location.reload();
                  })
            }else{
                Swal.fire({
                    title: 'Error with editing the news image!',
                  }).then((result) => {
                    location.reload();
                  })
            }
        })




    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Profile Authentication'
        }
    ]

    submit(form){
        
        var newsTitle = form.newsTitle;
        console.log(newsTitle);
        
        var newsDesciption = form.newsDesciption;
        console.log(newsDesciption);

       
        
        let details = {
            'id':this.selectedItem.id,
            'newsTitle':newsTitle,
            'newsDesciption':newsDesciption,
           
        }

        const uploadFormData = new FormData();
       
        uploadFormData.append('details', JSON.stringify(details));
        this.newsService.editDetails(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Successfully editted the news!',
                  }).then((result) => {
                    location.reload();
                  })
            }else{
                Swal.fire({
                    title: 'Error with editing the news!',
                  }).then((result) => {
                    location.reload();
                  })
            }
        })
       


    }

    fileSizeInBytes:any;
    public selectedFileVehicelReg: any = File;
    onFileSelectedVehicleReg(event: any) {
      const file = event.target.files[0];
      this.fileSizeInBytes = file.size;
      if(this.fileSizeInBytes>10485760){
        Swal.fire({
          title: 'Size of image should be less than 10240kb!',
        }).then((result) => {
        })
      }else{
        this.selectedFileVehicelReg = file;
      }
     
  
  
    }

}