import { Component, OnInit } from '@angular/core';
import { NewsServiceService } from 'src/app/service/news-service.service';

@Component({
  selector: 'app-home-demo-three',
  templateUrl: './home-demo-three.component.html',
  styleUrls: ['./home-demo-three.component.scss']
})
export class HomeDemoThreeComponent implements OnInit {

  singleBlogPost:[];
  constructor(private newsService:NewsServiceService) {
      this.newsService.getAllDetails().subscribe(res=>{
          this.singleBlogPost = res;
      })
   }

  ngOnInit(): void {
  }

  sectionTitle = [
    {
        title: "Our Latest News",
        paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
    }
]


}
