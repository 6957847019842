import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendServiceService } from './backend-service.service';

@Injectable({
  providedIn: 'root'
})
export class SendContactUsMailService {

  constructor(private httpClient:HttpClient,private backendService:BackendServiceService) { }

  saveCustomer(customerDetails:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'contact/contactusmail',{'contactUsDetails':customerDetails});
  }
}
