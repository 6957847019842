import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendServiceService } from './backend-service.service';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(private httpClient:HttpClient,private backendService:BackendServiceService) { }

  loginAdmin(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'admin/login',details);
  }
}
