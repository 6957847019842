import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-hometwo-banner',
    templateUrl: './hometwo-banner.component.html',
    styleUrls: ['./hometwo-banner.component.scss']
})
export class HometwoBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerItem = [
        {
            title: 'TAMRON 11-20mm F/2.8 Di III-A RXD (Model B060)',
            paragraph: 'A telephoto zoom so compact you want to carry around everyday. Superior optical performance and supreme lightweight portability.',
            buttonIcon: 'bx bx-show-alt',
            buttonText: 'View',
            buttonLink: 'shop-full-width-2',
            image: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b060_style_8_20210226.png?alt=media&token=ae9a0d45-7fba-48e2-aa48-9feaf7dd13ab'
        },
        {
            title: 'Tamron 17-70mm F/2.8 Di III-A VC RXD (Model B070)',
            paragraph: 'World’s first high-speed standard zoom lens for APS-C cameras with the focal length range of 17-70mm 4.1x zoom ratio. Outstanding optical performance.',
            buttonIcon: 'bx bx-show-alt',
            buttonText: 'View',
            buttonLink: 'shop-full-width-2',
            image: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b070_style_8_20201019.png?alt=media&token=b6954daa-73fe-4f0d-ab51-856ffd0b8314'
        },
        {
            title: 'TAMRON 28-75mm F/2.8 Di III RXD (Model A036)',
            paragraph: 'Superb optical performance, including both outstanding image quality and the beautiful background blur effects (bokeh) provided by a fast F/2.8 aperture. Comfortably lightweight (550g / 19.4 oz) and compact (117.8mm / 4.6 in).',
            buttonIcon: 'bx bx-show-alt',
            buttonText: 'View',
            buttonLink: 'shop-full-width-2',
            image: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a036_style.png?alt=media&token=9b2955e1-c189-4049-958d-a1997f6b0134'
        }
    ]

    homeSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		margin: 5,
		dots: false,
		autoplay: true,
		autoHeight: true,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
		autoplayHoverPause: true,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
                items: 1
			},
			576: {
                items: 1
			},
			768: {
                items: 1
			},
			992: {
                items: 1
			},
			1200: {
                items: 1
			}
		}
    }

}