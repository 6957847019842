import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductListService } from 'src/app/service/product-list.service';

@Component({
    selector: 'app-simple-product-page',
    templateUrl: './simple-product-page.component.html',
    styleUrls: ['./simple-product-page.component.scss']
})
export class SimpleProductPageComponent implements OnInit {
    itemId: string;
    constructor(private route: ActivatedRoute,private itemDetails:ProductListService) { }
    title :string;
    selectedDetails :any;
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.itemId = params['itemId']; 
            console.log("product_id "+this.itemId);

            const selectedItem = this.itemDetails.getDetails(this.itemId);
            this.selectedDetails = selectedItem;
            console.log("proooo "+JSON.stringify(selectedItem));
           
          });

    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Ergonomic Desk Sofa'
        }
    ]

}