
<app-navbar-style-two></app-navbar-style-two>

<div class="row">
    <div class="col-3">

    </div>
    <div class="col-6">
        <div class="input-box" >
            <i class="uil uil-search"></i>
            <input type="text" placeholder="Search"  name="search_value" [(ngModel)]="searchValue"/>
            <button class="button" [routerLink]="['/search-product', searchValue]" style="border:none;background: none;"> <i class='bx bx-search'></i></button>
          </div>
    </div>
    <div class="col-3">
        
    </div>
</div>

<script async src="https://www.googletagmanager.com/gtag/js?id=G-C8F6CSWD62"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-C8F6CSWD62');
</script>
   
