<!-- Start Bedroom Area -->
<!--section class="rooms-area pt-100 jarallax" data-jarallax='{"speed": 0.3}' *ngFor="let Content of roomsContent;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <div class="rooms-content">
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
            <ul>
                <li *ngFor="let List of Content.list">{{List.title}}</li>
            </ul>
            <a routerLink="/{{Content.buttonLink}}" class="default-btn"><i class='{{Content.buttonIcon}}'></i> {{Content.buttonText}}</a>
        </div>
    </div>
</section-->

<app-navbar-style-one></app-navbar-style-one>

<app-upcoming-products></app-upcoming-products>