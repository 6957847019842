import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackendServiceService {

  constructor() { }
  apiLocalUrl ="https://buycartradebackend.de.r.appspot.com/";
 // apiLocalUrl = "http://localhost:8080/";
}
