import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'app-navbar-style-two',
    templateUrl: './navbar-style-two.component.html',
    styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
    

    constructor(private router: Router) {
       this.classApplied=false;
     }

    ngOnInit(): void {
    }

    classApplied = false;
    
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    searchValue: string = '';

     searchClicked(){
        this.router.navigate(['/search-product', this.searchValue]);
       
     }


     isSearchOverlayVisible = false;

  toggleSearchOverlay() {
    console.log("toggle search clicked ");
    this.isSearchOverlayVisible = !this.isSearchOverlayVisible;
  }

  closeSearchOverlay() {
    this.isSearchOverlayVisible = false;
  }

  
}