import { Component, OnInit } from '@angular/core';
import { NewsServiceService } from 'src/app/service/news-service.service';

@Component({
    selector: 'app-shop-left-sidebar-page-one',
    templateUrl: './shop-left-sidebar-page-one.component.html',
    styleUrls: ['./shop-left-sidebar-page-one.component.scss']
})
export class ShopLeftSidebarPageOneComponent implements OnInit {

    singleBlogPost:[];
    constructor(private newsService:NewsServiceService) {
        this.newsService.getAllDetails().subscribe(res=>{
            this.singleBlogPost = res;
        })
     }

    ngOnInit(): void {
        
    }

    sectionTitle = [
        {
            title: "Our Latest News",
            paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
        }
    ]


}