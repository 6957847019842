
<app-navbar-style-one></app-navbar-style-one>

<div class="section-title">
    <h2>Edit News</h2>
    
</div>

<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-5 col-md-12">
                                    <div class="products-details-image">
                                        <a href="{{selectedItem.imageUrl}}" class="popup-btn">
                                            <img src="{{selectedItem.imageUrl}}" alt="image">
                                        </a>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-12">
                                    <div class="form-group mb-3">
                                        <input type="file" class="file-input" #fileUploadVehicleReg required accept=".jpg, .jpeg, .png"
                                        (change)="onFileSelectedVehicleReg($event)">
                                    </div>
                                    <button  (click)="saveImageChanges()" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> Save Image</button>
                                  
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <br>
                                    <div class="form-group mb-3">
                                        <p >News Title</p>
                                        <input required minlength="3" maxlength="50" ngModel name="newsTitle" type="text"  [ngModel]="selectedItem.newsTitle"  class="form-control" id="newsTitle" placeholder="News Title">
                                       
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                       
                                       
                                    </div>
                                </div>
                               

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <p>News Description</p>
                                        <input required ngModel name="newsDesciption" type="text" [ngModel]="selectedItem.newsDesciption" class="form-control" id="newsDesciption" placeholder="News Desciption">
                                        
                                    </div>
                                </div>
                              
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> SAVE CHANGES</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
</section>
