import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductListService {

  trending_status= 'TRENDING';
  new_status = 'NEW';
  old_status = 'OLD';
  popular_status = 'POPULAR';

  constructor() { }

  getProductsByStatus(status:any){
    const newProducts = this.singleProductsItem.filter(product => product.status === status);

// Log the array of new products to the console
    console.log('New Prosucts len '+newProducts.length);
    return newProducts;
  }

  getDetails(itemId:any){
    const selectedItem = this.singleProductsItem.find(item => item.id === itemId);

    // Do something with the selected item, such as displaying its details
    console.log("selected Item details "+JSON.stringify(selectedItem));
    return selectedItem;
  }

  getProductsInTitle(title:any){
    const matchingItems = this.singleProductsItem.filter(item => item.title.toLowerCase().includes(title.toLowerCase()));

    if (matchingItems.length === 0) {
        console.log("No matching items found for the provided title.");
        return matchingItems;
    }

    return matchingItems;
  }

  returnAllProducts(){
    return this.singleProductsItem;
  }

  singleProductsItem = [
    {
        id:'1',
        status:this.new_status,
        type:'DSLR_APS-C',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b018n_style_20150525.png?alt=media&token=763fe1ba-dab8-49b7-9478-c5f7b42cef33',
        title: 'TAMRON 18-200mm F/3.5-6.3 DI II VC ',
        model: 'Model B018',
        detailsLink: 'simple-product/1',
        shortDescription:'Covering focal lengths from 18 to 200mm, this compact zoom can take great shots in a myriad of situations. Frame shots instantly — no need to change lenses.',
        description:'',
        descriptionList:[
          'World\'s lightest in the 18-200mm class (400g / 14.1 oz.). ',
          'New high-performance AF drive module.',
          'VC (Vibration Compensation) for sharp handheld shooting.',
          'A moisture-resistant construction for shooting outdoors.',
          'Fantastic round blurring effects with a circular diaphragm.',
          'What sets this all-in-one™, high-power zoom lens apart.'
        ],
        specifications:[
          {
            type:'Model',
            value:'B018'
          },
          {
            type:'Focal Length',
            value:'18-200mm'
          },
          {
            type:'Angle of View (diagonal)',
            value:'75゜33\' - 7゜59\''
          },
          {
            type:'Optical Construction',
            value:'16 elements in 14 groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.49m/19.3 in. (at 180mm) <br> 0.77m/30.3 in. (at 35mm) <br> [at f=18, 200mm: 0.5m /19.7 in.] '
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:4 (at f=200mm: MOD 0.5m)'
          },
          {
            type:'Filter Size',
            value:'φ62mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ75mm'
          },
          {
            type:'Length*',
            value:'For Canon: 96.6mm (3.8 in.)* <br> For Nikon: 94.1mm (3.7 in.)*'
          },
          {
            type:'Weight',
            value:'400ｇ (14.1 oz.)*'
          },
          {
            type:'Aperture Blades',
            value:'7 (circular diaphragm)**'
          },
          {
            type:'Minimum Aperture',
            value:'F22-40'
          },
          {
            type:'Standard Accessories',
            value:'Flower-shaped lens hood, Lens caps'
          },

        ]
    },
    {
        id:'2',
        status:this.new_status,
        type:'DSLR_APS-C',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b023n_style_20170111.png?alt=media&token=12c18659-bc87-40f3-a0fd-46d3cbc7e23c',
        title: 'Tamron 10-24mm F/3.5-4.5 Di II VC HLD',
        model: 'Model B023',
        detailsLink: 'simple-product/2',
        shortDescription:'Inheriting the broadest focal length range in its class*1 of ultra-wide-angle zoom lenses. Improved optical performance across the entire zoom range.',
        description:'',
        descriptionList:[
          'Vibration Compensation that is especially useful in low light conditions.',
          'Equipped for the first time with the new HLD with outstanding driving power and stability',
          'Compressed into a compact body with structural ingenuity.',
          'An even more user-friendly lens with Fluorine Coating and Moisture-Resistant Construction.',
          'Electromagnetic diaphragm system now used also for Nikon-mount lenses.',
          'Compatible with TAMRON TAP-in ConsoleTM, an optional accessory product.',
          'External design placing importance on functionality and ease of use.'
        ],
        specifications:[
          {
            type:'Model',
            value:'B023'
          },
          {
            type:'Focal Length',
            value:'10-24mm'
          },
          {
            type:'Maximum Aperture',
            value:'F/3.5-4.5'
          },
          {
            type:'Angle of View (diagonal)',
            value:'108°44\'- 60°2\' (for APS-C format)'
          },
          {
            type:'Optical Construction',
            value:'16 elements in 11groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.24m (9.4 in)'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:5.3'
          },
          {
            type:'Filter Size',
            value:'φ77mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ83.6mm'
          },
          {
            type:'Length*',
            value:'For Canon 84.6mm (3.3 in) <br> For Nikon 82.1mm (3.2 in)'
          },
          {
            type:'Weight',
            value:'For Canon 440g (15.5 oz) <br> 	For Nikon 440g (15.5 oz)'
          },
          {
            type:'Aperture Blades',
            value:'7 (circular diaphragm)'
          },
          {
            type:'Image Stabilization Performance',
            value:'4 Stops (CIPA Standards Compliant). <br> For Canon: EOS-80D is used / For Nikon: D7200 is used'
          },
          {
            type:'Standard Accessories',
            value:'Lens hood, Lens caps'
          },
        ]
    },
    {
        id:'3',
        status:this.new_status,
        type:'DSLR_APS-C',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b028n_style_20170313.png?alt=media&token=eec95047-92d6-471d-86b1-6975be581034',
        title: 'Tamron 18-400mm F/3.5-4.5 Di II VC HLD ',
        model: 'Model B028',
        detailsLink: 'simple-product/3',
        shortDescription:'Inheriting the broadest focal length range in its class*1 of ultra-wide-angle zoom lenses. Improved optical performance across the entire zoom range.',
        description:'',
        descriptionList:[
          'Vibration Compensation that is especially useful in low light conditions.',
          'Equipped for the first time with the new HLD with outstanding driving power and stability.',
          'Compressed into a compact body with structural ingenuity.',
          'An even more user-friendly lens with Fluorine Coating and Moisture-Resistant Construction.',
          'Electromagnetic diaphragm system now used also for Nikon-mount lenses.',
          'Compatible with TAMRON TAP-in ConsoleTM, an optional accessory product.',
          'External design placing importance on functionality and ease of use.',
        ],
        specifications:[
          {
            type:'Model',
            value:'B028'
          },
          {
            type:'Focal Length',
            value:'10-24mm'
          },
          {
            type:'Maximum Aperture',
            value:'F/3.5-4.5'
          },
          {
            type:'Angle of View (diagonal)',
            value:'108°44\'- 60°2\' (for APS-C format)'
          },
          {
            type:'Optical Construction',
            value:'16 elements in 11groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.24m (9.4 in)'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:5.3'
          },
          {
            type:'Filter Size',
            value:'φ77mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ83.6mm'
          },
          {
            type:'Length*',
            value:'For Canon 84.6mm (3.3 in) <br> For Nikon 82.1mm (3.2 in)'
          },
          {
            type:'Weight',
            value:'For Canon 440g (15.5 oz) <br> 	For Nikon 440g (15.5 oz)'
          },
          {
            type:'Aperture Blades',
            value:'7 (circular diaphragm)'
          },
          {
            type:'Image Stabilization Performance',
            value:'4 Stops (CIPA Standards Compliant). <br> For Canon: EOS-80D is used / For Nikon: D7200 is used'
          },
          {
            type:'Standard Accessories',
            value:'Lens hood, Lens caps'
          },
        ]
    },
    {
        id:'4',
        status:this.new_status,
        type:'DSLR_Full-frame',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a010n_style_20140619.png?alt=media&token=ef281b07-d88c-4b5d-88cd-c085cb79a6ad',
        title: 'TAMRON 28-300mm F/3.5-6.3 Di VC PZD',
        model: 'Model A010',
        detailsLink: 'simple-product/4',
        shortDescription:'State-of-the-art optical design technology delivers superior image quality. PZD (Piezo Drive) delivers faster, quieter autofocus action.',
        description:'',
        descriptionList:[
          'Focus on portability The lens is now even smaller and lighter.',
          'Upgraded cosmetic design Elegant tungsten silver brand ring6.',
          'Circular diaphragm facilitates achieving spectacular blur effects.',
          'Moisture-resistant construction for shooting outdoors.',
          'VC (Vibration Compensation).',
          'Advanced anti-reflection coating'
        ],
        specifications:[
          {
            type:'Model',
            value:'A010'
          },
          {
            type:'Focal Length',
            value:'28-300mm'
          },
          {
            type:'Maximum Aperture',
            value:'F/3.5-6.3'
          },
          {
            type:'Angle of View (diagonal)',
            value:'75˚23’ – 8˚15’ (for full-frame format). <br>52˚58’ – 5˚20’ (for APS-C format).'
          },
          {
            type:'Optical Construction',
            value:'19 elements in 15 groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.49m/19.3 in'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:3.5 (at f=300mm: MFD 0.49m)'
          },
          {
            type:'Filter Size',
            value:'φ67mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ74.4mm'
          },
          {
            type:'Length*',
            value:'96.6mm (3.8 in.)*'
          },
          {
            type:'Weight',
            value:'540g (19 oz)*'
          },
          {
            type:'Aperture Blades',
            value:'7 (circular diaphragm)**'
          },
          {
            type:'Minimum Aperture',
            value:'F22-40'
          },
          {
            type:'Standard Accessories',
            value:'Flower-shaped lens hood'
          },
        ]

    },
    {
        id:'5',
        status:this.new_status,
        type:'DSLR_Full-frame',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a022n_style_20160705.png?alt=media&token=c299abd9-9824-4e02-8705-2fcde81dae33',
        title: 'Tamro SP 150-600mm F/5-6.3 Di VC USD G2 ',
        model: 'Model A022',
        detailsLink: 'simple-product/5',
        shortDescription:'. Optical design refreshed to achieve even higher performance. Tamron’s sophisticated eBAND Coating for eliminating ghosting and flare.',
        description:'',
        descriptionList:[
          'MOD reduced to provide optimum tele-macro photography.',
          'AF speed is faster and much more responsive with moving subjects.',
          'VC performance is now 4.5 stops and offers three modes optimized for different situations.',
          'New FLEX ZOOM LOCK mechanism enables the locking of the zoom ring at any position.',
          'Fluorine Coating and the Moisture-Resistant Construction for a more user-friendly lens.',
          'Electromagnetic diaphragm system now used also for Nikon-mount lenses.',
          'The lightweight and easy-to-hold tripod mount that is compatible with an Arca-Swiss style quick release plate.',
          'Compatible with TAMRON TAP-in ConsoleTM, an optional accessory product.',
          'The tele converters exclusively for the Tamron lens now developed.',
          'Based on the rigorous quality standards worthy of the SP series, this new lens is manufactured with thorough attention to details.'
        ],
        specifications:[
          {
            type:'Model',
            value:'A022'
          },
          {
            type:'Focal Length',
            value:'150-600mm'
          },
          {
            type:'Maximum Aperture',
            value:'F/5-6.3'
          },
          {
            type:'Angle of View (diagonal)',
            value:'16°25\' - 4°8\' (for full-frame format). <br>10°38\' - 2°40\'  (for APS-C format).'
          },
          {
            type:'Optical Construction',
            value:'21 elements in 13 groups'
          },
          {
            type:'Minimum Object Distance',
            value:'2.2m (86.6 in)'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:3.9'
          },
          {
            type:'Filter Size',
            value:'φ95mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ108.4mm'
          },
          {
            type:'Length*',
            value:'For Canon 260.2mm (10.2 in). <br>For Nikon 257.7mm (10.1 in).'
          },
          {
            type:'Weight**',
            value:'For Canon 2,010g (70.9 oz). <br>For Nikon 1,990g (70.2 oz).'
          },
          {
            type:'Aperture Blades',
            value:'9 (circular diaphragm)'
          },
          {
            type:'Minimum Aperture',
            value:'F/32-40'
          },
          {
            type:'Image Stabilization Performance',
            value:'4.5stops (CIPA Standards Compliant)<br>.Using in VC MODE 3<br>(For Canon : EOS-5D MKIII is used / For Nikon : D810 is used)'
          },
          {
            type:'Standard Accessories',
            value:'Lens hood, Lens caps, Lens case'
          },
         
        ]
    },
    {
        id:'6',
        status:this.trending_status,
        type:'DSLR_Full-frame',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a025n_style_20170106.png?alt=media&token=6bc4d980-96b4-4756-84a6-b0e520c09f18',
        title: 'Tamron SP 70-200mm F/2.8 Di VC USD G2',
        model: 'Model A025',
        detailsLink: 'simple-product/6',
        shortDescription:'Outstanding resolution and contrast reproduction performance, beautiful bokeh and full compatibility with Tamron tele converters. Improved VC system provides highest Vibration Compensation ability in its class*1 (5 stops using CIPA standard compliant) and offers three situation-specific VC modes.',
        description:'',
        descriptionList:[
          'Demonstratively improved autofocus speed and responsiveness.',
          'Focuses closer (shorter Minimum Object Distance, or MOD) for greater versatility.',
          'Optimized and exclusively designed eBAND Coating.',
          'Moisture-Proof and Dust-Resistant Construction.',
          'Fluorine Coating for greater protection.',
          'Electromagnetic diaphragm system now used also for Nikon-mount lenses.',
          'Lightweight and easy-to-hold tripod mount is compatible with Arca-Swiss style quick release plates.',
          'Compatible with TAMRON TAP-in ConsoleTM, an optional accessory product.',
          'Based on the rigorous quality standards worthy of the SP series, this new lens is manufactured with a thorough attention to details.'
        ],
        specifications:[
          {
            type:'Model',
            value:'A025'
          },
          {
            type:'Focal Length',
            value:'70-200mm'
          },
          {
            type:'Maximum Aperture',
            value:'F/2.8'
          },
          {
            type:'Angle of View (diagonal)',
            value:'34°21\' - 12°21\' (for full-frame format). <br>22°33\' - 7°59\' (for APS-C format).'
          },
          {
            type:'Optical Construction',
            value:'23 elements in 17 groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.95m (37.4 in)'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:6.1'
          },
          {
            type:'Filter Size',
            value:'φ77mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ88mm'
          },
          {
            type:'Length*',
            value:'For Canon 193.8mm (7.6 in).<br>For Nikon 191.3mm (7.5 in).'
          },
          {
            type:'Weight**',
            value:'For Canon 1,500g (52.9 oz).<br>For Nikon 1,485g (52.4 oz)'
          },
          {
            type:'Aperture Blades',
            value:'9 (circular diaphragm)'
          },
          {
            type:'Minimum Aperture',
            value:'F/22'
          },
          {
            type:'Image Stabilization Performance',
            value:'5 stops (CIPA Standards Compliant).<br>Using in VC MODE 3.<br>(For Canon : EOS-5D MKIII is used / For Nikon : D810 is used).'
          },
          {
            type:'Standard Accessories',
            value:'Lens hood, Lens caps, Lens case, Detachable tripod mount.'
          },
         
        ]
    },
    {
        id:'7',
        status:this.popular_status,
        type:'Mirrorless_APS-C',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b060_style_8_20210226.png?alt=media&token=ae9a0d45-7fba-48e2-aa48-9feaf7dd13ab',
        title: 'TAMRON 11-20mm F/2.8 Di III-A RXD',
        model: 'Model B060',
        detailsLink: 'simple-product/7',
        shortDescription:'The world\'s first F2.8 ultra wide-angle zoom lens for APS-C mirrorless cameras.',
        description:'',
        descriptionList:[
          'Compact and light weight for superior usability.',
          'Outstanding optical performance.',
          'MOD of 0.15m (5.9 in) at the 11mm focal length.',
          'A user-friendly unified 67mm filter size across the series.',
          'The RXD (Rapid eXtra-silent stepping Drive) stepping motor unit is exceptionally quiet and perfect for video use.',
          'Moisture-Resistant Construction and Fluorine Coating provide extra protection.',
          'Compatible with many camera-specific features and functions, including Fast Hybrid AF and Eye AF.'
        ],
        specifications:[
          {
            type:'Model',
            value:'B060'
          },
          {
            type:'Focal Length',
            value:'11-20mm <br>(For full-frame mirrorless format: 16.5-30mm equivalent field-of-view)'
          },
          {
            type:'Maximum Aperture',
            value:'F2.8'
          },
          {
            type:'Angle of View (diagonal)',
            value:'105° 20\'-71° 35\'　(for APS-C mirrorless format).'
          },
          {
            type:'Optical Construction',
            value:'12 elements in 10 groups.'
          },
          {
            type:'Minimum Object Distance',
            value:'0.15m /5.9 in (WIDE), 0.24m /9.4 in (TELE).'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:4 (WIDE) / 1:7.6 (TELE).'
          },
          {
            type:'Filter Size',
            value:'φ67mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ73mm'
          },
          {
            type:'Length*',
            value:'86.2mm (3.4 in)'
          },
          {
            type:'Weight**',
            value:'335g (11.8 oz)'
          },
          {
            type:'Aperture Blades',
            value:'7 (circular diaphragm)**'
          },
          {
            type:'Minimum Aperture',
            value:'F16'
          },
          
          {
            type:'Standard Accessories',
            value:'Flower-shaped hood, Lens caps'
          },
         
        ]
    },
    { 
        id:'8',
        status:this.new_status,
        type:'Mirrorless_APS-C',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b061e_style_20210601.png?alt=media&token=650489d7-2520-4e21-ad25-34c0e310e232',
        title: 'TAMRON 18-300mm F/3.5-6.3 Di III-A VC VXD',
        model: 'Model B061',
        detailsLink: 'simple-product/8',
        shortDescription:'The world’s first 16.6x all-in-one zoom lens for APS-C mirrorless cameras. Best-in-class, extremely fast and precise AF provided by VXD linear motor focus mechanism.',
        description:'',
        descriptionList:[
          'MOD of 0.15m (5.9 in) and maximum magnification ratio of 1:2 at wide end.',
          'The best image quality in its class.',
          'Outstanding VC stabilization.',
          'Comfortably compact.',
          'User-friendly features (Moisture-Resistant Construction, Fluorine Coating, Zoom Lock switch).',
          'Compatible with many camera-specific features and functions, including Fast Hybrid AF and Eye AF.'
        ],
        specifications:[
          {
            type:'Model',
            value:'B061'
          },
          {
            type:'Focal Length',
            value:'18-300mm (for APS-C frame mirrorless format)<br> (For full-frame mirrorless format: 27-450mm equivalent field-of-view)'
          },
          {
            type:'Maximum Aperture',
            value:'F3.5-6.3'
          },
          {
            type:'Angle of View (diagonal)',
            value:'77° 24\'-5° 30\'　(for APS-C frame mirrorless format)'
          },
          {
            type:'Optical Construction',
            value:'19 elements in 15 groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.15m /5.9 in (WIDE), 0.99m /39 in (TELE)'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:2 (WIDE) / 1:4 (TELE)'
          },
          {
            type:'Filter Size',
            value:'φ67mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ75.5mm'
          },
          {
            type:'Length*',
            value:'For Sony E-mount 125.6mm (4.9 in)'
          },
          {
            type:'Weight**',
            value:'620g (21.9 oz)'
          },
          {
            type:'Aperture Blades',
            value:'7 (circular diaphragm)**'
          },
          {
            type:'Minimum Aperture',
            value:'F22-40'
          },
          {
            type:'Standard Accessories',
            value:'Flower-shaped hood, Lens caps'
          },
         
        ]
    },
    {
        id:'9',
        status:this.new_status,
        type:'Mirrorless_APS-C',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b070_style_8_20201019.png?alt=media&token=b6954daa-73fe-4f0d-ab51-856ffd0b8314',
        title: 'Tamron 17-70mm F/2.8 Di III-A VC RXD',
        model: 'Model B070',
        detailsLink: 'simple-product/9',
        shortDescription:'World’s first high-speed standard zoom lens for APS-C cameras with the focal length range of 17-70mm 4.1x zoom ratio.',
        description:'',
        descriptionList:[
          'Outstanding optical performance',
          'Upgraded VC effective in combination with Sony APS-C mirrorless cameras, leveraging AI for video shooting.',
          'Close focusing−MOD is just 0.19m (7.5 in) at wide-angle end.',
          'A highly portable compact design.',
          'The RXD (Rapid eXtra-silent stepping Drive) stepping motor unit is exceptionally quiet and perfect for video use.',
          'Moisture-Resistant Construction and Fluorine Coating provide extra protection.',
          'Compatible with many camera-specific features and functions, including Fast Hybrid AF and Eye AF.'
        ],
        specifications:[
          {
            type:'Model',
            value:'B070'
          },
          {
            type:'Focal Length',
            value:'17-70mm (for APS-C frame mirrorless format) <br> (for full-frame mirrorless format: 25.5-105mm equivalent field-of-view)'
          },
          {
            type:'Maximum Aperture',
            value:'F2.8'
          },
          {
            type:'Angle of View (diagonal)',
            value:'79° 55\'-23° 00\'　(for APS-C frame mirrorless format)'
          },
          {
            type:'Optical Construction',
            value:'16 elements in 12 groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.19m /7.5 in (WIDE), 0.39m /15.4 in (TELE)'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:4.8 (WIDE) / 1:5.2 (TELE)'
          },
          {
            type:'Filter Size',
            value:'φ67mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ74.6mm'
          },
          {
            type:'Length*',
            value:'119.3mm (4.7 in)'
          },
          {
            type:'Weight**',
            value:'525g (18.5 oz)'
          },
          {
            type:'Aperture Blades',
            value:'9 (circular diaphragm)**'
          },
          {
            type:'Minimum Aperture',
            value:'F22'
          },
          {
            type:'Standard Accessories',
            value:'Flower-shaped hood, Lens caps'
          },
         
        ]
    },
    {
        id:'10',
        status:this.trending_status,
        type:'Mirrorless_Full-frame',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a036_style.png?alt=media&token=9b2955e1-c189-4049-958d-a1997f6b0134',
        title: 'TAMRON 28-75mm F/2.8 Di III RXD',
        model: 'Model A036',
        detailsLink: 'simple-product/10',
        shortDescription:'Superb optical performance, including both outstanding image quality and the beautiful background blur effects (bokeh) provided by a fast F/2.8 aperture.',
        description:'',
        descriptionList:[
          'Comfortably lightweight (550g / 19.4 oz) and compact (117.8mm / 4.6 in).',
          'Minimum Object Distance (MOD) 0.19m (7.5 in); Working Distance 5.7cm (2.24 in).',
          'All-new “RXD” stepping motor AF unit is excellently quiet and therefore perfect for video capture.',
          'Consistent with Tamron’s exciting, ergonomically superb next-generation design.',
          'Moisture-Resistant Construction and Fluorine Coating for weather protection.',
          'Compatible with main camera-specific features and functions.<br>Tamron’s new 28-75mm zoom is compatible with many of the advanced features that are specific to certain mirrorless cameras. This includes the following:<br>- Fast Hybrid AF <br>- Eye AF <br>- Direct Manual Focus (DMF) <br>- In-camera lens correction (shading, chromatic aberration, distortion) <br>- Camera-based lens unit firmware updates. <br>* Features vary by camera. Please consult your camera’s instruction manual for details.<br>* As of March, 2018. '
        ],
        specifications:[
          {
            type:'Model',
            value:'A036'
          },
          {
            type:'Focal Length',
            value:'28‐75mm'
          },
          {
            type:'Maximum Aperture',
            value:'F/2.8'
          },
          {
            type:'Angle of View (diagonal)',
            value:'75°23\'-32°11\'　 (for full-frame format) <br>52°58\' -21°05\'　(for APS-C format)'
          },
          {
            type:'Optical Construction',
            value:'15 elements in 12 groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.19m (7.5 in) (WIDE) / 0.39m (15.3 in) (TELE)'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:2.9 (WIDE) / 1:4 (TELE)'
          },
          {
            type:'Filter Size',
            value:'φ67mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ73mm '
          },
          {
            type:'Length*',
            value:'117.8mm (4.6 in)'
          },
          {
            type:'Weight**',
            value:'550g (19.4 oz)'
          },
          {
            type:'Aperture Blades',
            value:'9 (circular diaphragm)'
          },
          {
            type:'Minimum Aperture',
            value:'F/22'
          },
          {
            type:'Standard Accessories',
            value:'Lens hood, Lens caps'
          },
         
        ]
    },
    {
        id:'11',
        status:this.popular_status,
        type:'Mirrorless_Full-frame',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a046s_style_20190411.png?alt=media&token=4ca6c37e-3c03-4a40-89fa-05e9737292f4',
        title: 'Tamron 70-300mm F/4.5-6.3 Di III RXD',
        model: 'Model A046',
        detailsLink: 'simple-product/11',
        shortDescription:'Companion model to the compact, popular Tamron 28-75mm F/2.8 Di III RXD (Model A036) zoom',
        descriptionList:[
          'Superb high resolution performance.',
          'Extreme close-up capability enables versatile creative expression with ultra wide-angle zoom.',
          'The RXD stepping motor unit is exceptionally quiet.',
          'Moisture-Resistant Construction and Fluorine Coating.',
          'Consistent design and operation throughout all Tamron lenses for full-frame mirrorless cameras.',
          'Versatile, exciting combination when paired with the 28-75mm F/2.8 Di III RXD (Model A036).',
          'Compatible with main camera-specific features and functions. <br> Tamron’s new 17-28mm zoom is compatible with many of the advanced features that are specific to certain mirrorless cameras. This includes the following: <br>- Fast Hybrid AF <br>- Eye AF <br>- Direct Manual Focus (DMF) <br>- In-camera lens correction (shading, chromatic aberration, distortion) <br>- Camera-based lens unit firmware updates'
        ],
        description:'',
        specifications:[
          {
            type:'Model',
            value:'A046'
          },
          {
            type:'Focal Length',
            value:'17‐28mm'
          },
          {
            type:'Maximum Aperture',
            value:'F/2.8'
          },
          {
            type:'Angle of View (diagonal)',
            value:'103°41\'-75°23\'　 (for full-frame mirrorless format)'
          },
          {
            type:'Optical Construction',
            value:'13 elements in 11 groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.19m (7.5 in) (WIDE) / 0.26m (10.2 in) (TELE)'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:5.2 (WIDE) / 1:6 (TELE)'
          },
          {
            type:'Filter Size',
            value:'φ67mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ73mm'
          },
          {
            type:'Length*',
            value:'99mm (3.9 in)'
          },
          {
            type:'Weight**',
            value:'420g (14.8 oz)'
          },
          {
            type:'Aperture Blades',
            value:'9 (circular diaphragm)**'
          },
          {
            type:'Minimum Aperture',
            value:'F/22'
          },
          {
            type:'Standard Accessories',
            value:'Lens hood, Lens caps'
          },
         
        ]

    },
    {
        id:'12',
        status:this.popular_status,
        type:'Mirrorless_Full-frame',
        mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a047_style_8_200727.png?alt=media&token=7741a38a-e852-483c-a1a6-f38ead6c2e1a',
        title: 'Tamron 70-300mm F/4.5-6.3 Di III RXD',
        model: 'Model A047',
        detailsLink: 'simple-product/12',
        shortDescription:'A telephoto zoom so compact you want to carry around everyday.',
        descriptionList:[
          'Superior optical performance and supreme lightweight portability.',
          'The RXD stepping motor unit is exceptionally quiet.',
          'A full family of superb, compact lenses made especially for mirrorless cameras.',
          'Moisture-Resistant Construction for added protection.',
          'Compatible with camera-specific features and functions. <br>Tamron’s new 70-300mm F4.5-6.3 is compatible with many of the advanced features that are specific to certain mirrorless cameras. These include the following:<br>- Fast Hybrid AF<br>- Eye AF<br>- Direct Manual Focus (DMF)<br>- In-camera lens correction (shading, chromatic aberration, distortion)<br>- Camera-based lens unit firmware updates'
        ],
        description:'',
        specifications:[
          {
            type:'Model',
            value:'A047'
          },
          {
            type:'Focal Length',
            value:'70-300mm'
          },
          {
            type:'Maximum Aperture',
            value:'F4.5-6.3'
          },
          {
            type:'Angle of View (diagonal)',
            value:'34°21\'-8°15\'　(for full-frame mirrorless format)'
          },
          {
            type:'Optical Construction',
            value:'15 elements in 10 groups'
          },
          {
            type:'Minimum Object Distance',
            value:'0.8m /31.5 in (WIDE), 1.5m /59.1 in (TELE)'
          },
          {
            type:'Maximum Magnification Ratio',
            value:'1:9.4 (WIDE) / 1:5.1 (TELE)'
          },
          {
            type:'Filter Size',
            value:'φ67mm'
          },
          {
            type:'Maximum Diameter',
            value:'φ77mm '
          },
          {
            type:'Length*',
            value:'148mm (5.8 in)'
          },
          {
            type:'Weight**',
            value:'545g (19.2 oz)'
          },
          {
            type:'Aperture Blades',
            value:'7 (circular diaphragm)**'
          },
          {
            type:'Minimum Aperture',
            value:'F22-32'
          },
          {
            type:'Standard Accessories',
            value:'Round-shaped hood, Lens caps'
          }, 
        ]
    },
    {
      id:'13',
      status:this.popular_status,
      type:'Mirrorless_Full-frame',
      mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a056_style_withlens_20191211.png?alt=media&token=2f84f9a9-4d4e-4ff2-85c2-be44438f4f03',
      title: 'Tamron 70-300mm F/4.5-6.3 Di III RXD',
      model: 'Model A056',
      detailsLink: 'simple-product/13',
      shortDescription:'A telephoto zoom so compact you want to carry around everyday. Superior optical performance and supreme lightweight portability.',
      descriptionList:[
        'The RXD stepping motor unit is exceptionally quiet.',
        'A full family of superb, compact lenses made especially for mirrorless cameras.',
        'Moisture-Resistant Construction for added protection.',
        'Compatible with camera-specific features and functions. <br>Tamron’s new 70-300mm F4.5-6.3 is compatible with many of the advanced features that are specific to certain mirrorless cameras. These include the following:<br>- Fast Hybrid AF<br>- Eye AF<br>- Direct Manual Focus (DMF)<br>- In-camera lens correction (shading, chromatic aberration, distortion)<br>- Camera-based lens unit firmware updates.'
      ],
      description:'',
      specifications:[
        {
          type:'Model',
          value:'Model A056'
        },
        {
          type:'Focal Length',
          value:'70-300mm'
        },
        {
          type:'Maximum Aperture',
          value:'F4.5-6.3'
        },
        {
          type:'Angle of View (diagonal)',
          value:'34°21\'-8°15\'　(for full-frame mirrorless format)'
        },
        {
          type:'Optical Construction',
          value:'15 elements in 10 groups'
        },
        {
          type:'Minimum Object Distance',
          value:'0.8m /31.5 in (WIDE), 1.5m /59.1 in (TELE)'
        },
        {
          type:'Maximum Magnification Ratio',
          value:'1:9.4 (WIDE) / 1:5.1 (TELE)'
        },
        {
          type:'Filter Size',
          value:'φ67mm'
        },
        {
          type:'Maximum Diameter',
          value:'φ77mm'
        },
        {
          type:'Length*',
          value:'148mm (5.8 in)'
        },
        {
          type:'Weight',
          value:'545g (19.2 oz)'
        },
        {
          type:'Aperture Blades',
          value:'7 (circular diaphragm)**'
        },
        {
          type:'Minimum Aperture',
          value:'F22-32'
        },
        {
          type:'Standard Accessories',
          value:'Round-shaped hood, Lens caps'
        },
       
      ]
  },
  
{
  id:'14',
  status:this.trending_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a057_style_8_20210108.png?alt=media&token=8883ae5e-85af-4538-af72-5533920b4567',
  title: 'TAMRON 150-500mm F/5-6.7 Di III VC VXD',
  model: 'Model A057',
  detailsLink: 'simple-product/14',
  shortDescription:'A hand-holdable full-frame 500mm lens that defies conventional wisdom.',
  descriptionList:[
    'Amazing high image quality despite its compact size.',
    'VXD AF motor provides high speed, high precision, and excellent quietness.',
    'TAMRON’s acclaimed Vibration Compensation delivers excellent image stabilization',
    'Exciting telephoto macro focuses as close as 0.6m (23.6 in)',
    'Additional features for greater convenience.<br>- Arca-Swiss compatible tripod mount with strap attachment holes included.<br>- Lens hood with flexible, protective front portion.<br>- New switch design for improved operability.<br> - FLEX ZOOM LOCK mechanism to support greater usability.',
    'BBAR-G2 Coating minimizes ghosting and flare.',
    'Moisture-Resistant Construction and Fluorine Coating provide extra protection.',
    'Compatible with many camera-specific features and functions, including Fast Hybrid AF and Eye AF.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'Model A057'
    },
    {
      type:'Focal Length',
      value:'150-500mm'
    },
    {
      type:'Maximum Aperture',
      value:'F5-6.7'
    },
    {
      type:'Angle of View (diagonal)',
      value:'16° 25\'-4° 57\'　(for full-frame mirrorless format)'
    },
    {
      type:'Optical Construction',
      value:'25 elements in 16 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.6m /23.6 in (WIDE), 1.8m /70.9 in (TELE)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:3.1 (WIDE) / 1:3.7 (TELE)'
    },
    {
      type:'Filter Size',
      value:'φ82mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ93mm'
    },
    {
      type:'Length*',
      value:'209.6mm (8.3 in)'
    },
    {
      type:'Weight**',
      value:'1,725g (60.8 oz) (without tripod mount) / Tipod mount 155g (5.5 oz)'
    },
    {
      type:'Aperture Blades',
      value:'7 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F22-32'
    },
    {
      type:'Standard Accessories',
      value:'Round-shaped hood, Lens caps, Tripod mount'
    },
   
  ]
},
{
  id:'15',
  status:this.trending_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a058_style8_dist_20210729.png?alt=media&token=d281c7ac-6aa3-4204-a2b6-797fbc3de797',
  title: 'TAMRON 35-150mm F/2-2.8 Di III VXD ',
  model: 'Model A058',
  detailsLink: 'simple-product/15',
  shortDescription:'The world\'s first F2-2.8 fast-aperture zoom lens. 2.	Versatile zoom range provides seamless shooting from wide-angle 35mm to telephoto 150mm.',
  descriptionList:[
    'Outstanding optical performance throughout the zoom range.',
    'Fast, quiet VXD linear motor mechanism for high-speed and high-precision autofocus.',
    'Superior close-focusing capability with an MOD of 0.33m (13 in) at the wide end for creative close-ups.',
    'Compact body with 35-150mm zoom range and fast F2 maximum aperture.',
    'New design provides enhanced grip and greater intuitive operation.',
    'Moisture-Resistant Construction, Fluorine Coating, and hood with locking mechanism for greater convenience.',
    'Compatible with many camera-specific features and functions, including Fast Hybrid AF and Eye AF.'


  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'Model A058'
    },
    {
      type:'Focal Length',
      value:'35-150mm'
    },
    {
      type:'Maximum Aperture',
      value:'F2-2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'63゜26′- 16゜25′ (for full-frame mirrorless format)'
    },
    {
      type:'Optical Construction',
      value:'21 elements in 15 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.33m /13 in (WIDE), 0.85m /33.5 in (TELE)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:5.7 (WIDE)/ 1:5.9 (TELE)'
    },
    {
      type:'Filter Size',
      value:'φ82mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ89.2mm'
    },
    {
      type:'Length*',
      value:'158mm (6.2 in'
    },
    {
      type:'Weight**',
      value:'1,165g (41.1 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)** '
    },
    {
      type:'Minimum Aperture',
      value:'F16-22'
    },
   
    {
      type:'Standard Accessories',
      value:'Flower-shaped hood (with locking mechanism), Lens caps'
    },
   
  ]
},
{
  id:'16',
  status:this.old_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a062_style_20220628.jpg?alt=media&token=0b33ff4d-39fc-47e7-b3eb-80d56fee9539',
  title: 'TAMRON 20-40mm F/2.8 Di III VXD ',
  model: 'Model A062',
  detailsLink: 'simple-product/16',
  shortDescription:'Fast F2.8 aperture, compact size and exceptionally versatile 20-40mm zoom range. Fabulous family videos, vlogs, and other online content.',
  descriptionList:[
    'High image quality in a compact, lightweight body.',
    'TAMRON’s VXD linear motor for high-level AF speed and precision',
    'Wide macro shooting: MOD of 0.17m (6.7 in) and maximum magnification ratio of 1:3.8 at the wide end.',
    'Rapid zooming (Zoom ring rotation arc is just 65°).',
    'TAMRON Lens UtilityTM software for Focus Ring Function Setting and firmware updates.',
    'New design with improved texture and scratch resistance.',
    '67mm filter size, same as most other TAMRON lenses for mirrorless cameras.',
    'User-friendly features (Moisture-Resistant Construction and Fluorine Coating)',
    'Compatible with many camera-specific features and functions, including Fast Hybrid AF and Eye AF.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'Model A062'
    },
    {
      type:'Focal Length',
      value:'20-40mm'
    },
    {
      type:'Maximum Aperture',
      value:'F2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'94° 30\'-56° 49\'　(for full-frame mirrorless format)'
    },
    {
      type:'Optical Construction',
      value:'12 elements in 11 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.17m /6.7 in (WIDE), 0.29m /11.4 in (TELE)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:3.8 (WIDE) / 1:5.1 (TELE)'
    },
    {
      type:'Filter Size',
      value:'φ67mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ74.4mm '
    },
    {
      type:'Length*',
      value:'86.5mm (3.4 in)'
    },
    {
      type:'Weight**',
      value:'365g (12.9 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F22'
    },
    {
      type:'Standard Accessories',
      value:'Flower-shaped hood, Lens caps'
    },
   
  ]
},
{
  id:'17',
  status:this.old_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a063_style8_dist_20210729.png?alt=media&token=63a6b7f3-a669-46bc-a41e-b8706bdefcdd',
  title: 'TAMRON 28-75mm F/2.8 Di III VXD G2',
  model: 'Model A063',
  detailsLink: 'simple-product/17',
  shortDescription:'Best image quality in standard zoom lens class.',
  descriptionList:[
    'Fast, quiet VXD linear motor for high-speed and high-precision autofocus.',
    'MOD of 0.18m (7.1 in) at wide end and maximum magnification ratio of 1:2.7 for creative close-ups.',
    'New design provides enhanced texture and greater user-friendliness.',
    'Lightweight, compact design with emphasis on ease of use, plus 67mm filter size.',
    'Compatible with many camera-specific features and functions, including Fast Hybrid AF and Eye AF.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'Model A063'
    },
    {
      type:'Focal Length',
      value:'28-75mm'
    },
    {
      type:'Maximum Aperture',
      value:'F2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'75゜23′- 32゜11′ (for full-frame mirrorless format)'
    },
    {
      type:'Optical Construction',
      value:'17 elements in 15 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.18m /7.1 in (WIDE), 0.38m /15 in (TELE)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:2.7 (WIDE)/ 1:4.1 (TELE)'
    },
    {
      type:'Filter Size',
      value:'φ67mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ75.8mm'
    },
    {
      type:'Length*',
      value:'117.6mm (4.6 in)'
    },
    {
      type:'Weight**',
      value:'540g (19 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F22'
    },
    {
      type:'Standard Accessories',
      value:'Flower-shaped hood, Lens caps'
    },
   
  ]
},
{
  id:'18',
  status:this.old_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/A065_00_style_0512.png?alt=media&token=ef69d19c-2b6e-4096-82e6-ae3f08e88b15',
  title: 'TAMRON 70-180mm F/2.8 Di III VC VXD G2',
  model: 'Model A065',
  detailsLink: 'simple-product/18',
  shortDescription:'Even with VC image stabilization, the lens is just 156.5mm (6.2in) long and weighs only 855g (30.2oz).',
  descriptionList:[
    'Revamped optical design delivers truly superb performance at all focal lengths.',
    'VXD provides high-speed and high-precision AF with vastly improved subject tracking.',
    'Equipped with TAMRON’s proprietary VC mechanism.',
    'Achieves MOD of 0.3m (11.8in) at 70mm and 0.85m (33.5in) at 180mm.',
    'Proprietary software tool TAMRON Lens Utility for lens function customization.',
    'Additional features for greater convenience.',
    'User-friendly features (Moisture-Resistant Construction and Fluorine Coating).',
    'Compatible with many camera-specific features and functions, including Fast Hybrid AF and Eye AF.',
    '67mm filter size, same as most other TAMRON lenses for mirrorless cameras.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'Model A065'
    },
    {
      type:'Focal Length',
      value:'70-180mm'
    },
    {
      type:'Maximum Aperture',
      value:'F2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'34°21\'-13°42\'　(for full-frame mirrorless format)'
    },
    {
      type:'Optical Construction',
      value:'20 elements in 15 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.3m / 11.8in (WIDE), 0.85m / 33.5in (TELE)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:2.6 (WIDE) / 1:4.7 (TELE)'
    },
    {
      type:'Filter Size',
      value:'φ67mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ83mm'
    },
    {
      type:'Length*',
      value:'156.5mm (6.2in)'
    },
    {
      type:'Weight**',
      value:'855g (30.2oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)'
    },
    {
      type:'Minimum Aperture',
      value:' F22'
    },
    {
      type:'Standard Accessories',
      value:'Flower-shaped hood, Lens caps'
    },
   
  ]
},
{
  id:'19',
  status:this.old_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a067_style_20220517.png?alt=media&token=cc05d3b5-0945-4229-97d9-6cbe625533cf',
  title: 'Tamron 50-400mm F/4.5-6.3 Di III VC VXD ',
  model: 'Model A067',
  detailsLink: 'simple-product/19',
  shortDescription:'Expansive 8x zoom ratio starts at 50mm and extends to 400mm ultra-telephoto with a single lens. Unfaltering high image quality across the entire zoom range.',
  descriptionList:[
    'VXD produces high-speed and high-precision AF.',
    'Equipped with TAMRON’s proprietary VC image stabilization',
    'Enhanced operational and versatile features. <br> 1) Connector Port for the TAMRON Lens Utility. <br>2) New “Focus Limiter” function added to the TAMRON Lens Utility. <br>3) Rapid zooming (Zoom ring rotation arc is just 75°). <br>4) Optional tripod mount (Arca-Swiss compatible).',
    'Super-compact with a length of only 183.4mm (7.2 in) and a weight of 1,155g (40.7 oz)',
    'Half-macro photography with magnification ratio of 1:2 at the 50mm setting.',
    'New design with improved texture and scratch resistance.',
    '67mm filter size, same as most other TAMRON lenses for mirrorless cameras.',
    'User-friendly features (Moisture-Resistant Construction, Fluorine Coating, Zoom Lock switch, etc.).',
    'Compatibility with many camera-specific features and functions, including Fast Hybrid AF and Eye AF.'

  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'Model A067'
    },
    {
      type:'Focal Length',
      value:'50-400mm'
    },
    {
      type:'Maximum Aperture',
      value:'F2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'46°48\'- 6°11\'(for APS-C frame mirrorless format)'
    },
    {
      type:'Optical Construction',
      value:'24 elements in 18 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.25m (9.8 in) (WIDE) /1.5m (59.1 in) (TELE)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:2 (WIDE) / 1:4 (TELE)'
    },
    {
      type:'Filter Size',
      value:'φ67mm'
    },
    {
      type:'Maximum Diameter',
      value:'Φ88.5mm'
    },
    {
      type:'Length*',
      value:'183.4mm / 7.2 in'
    },
    {
      type:'Weight**',
      value:'1,155g / 40.7 oz'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F22-32'
    },
    {
      type:'Standard Accessories',
      value:'Flower-shaped hood, Lens caps'
    },
   
  ]
},
{
  id:'20',
  status:this.old_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/A068s_00_style_0601.png?alt=media&token=187a9217-cb13-49e9-9546-250892071976',
  title: 'TAMRON 17-50mm F/4 Di III VXD ',
  model: 'Model A068',
  detailsLink: 'simple-product/20',
  shortDescription:'Zoom lens that covers ultra wide-angle to standard focal length at constant F4 aperture.',
  descriptionList:[
    'High optical performance and image quality throughout the entire zoom range.',
    'Compact for easy handling with internal zoom for superlative balance.',
    'Fast and accurate AF for still and video shooting.',
    'Outstanding close-range shooting performance expands versatility.',
    'Additional features for greater convenience.',
    'Proprietary software tool TAMRON Lens UtilityTM expands the possibilities of still imaging and video capture.',
    'User-friendly features (Moisture-Resistant Construction and Fluorine Coating).',
    '67mm filter size, same as most other TAMRON lenses for mirrorless cameras'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'Model A068'
    },
    {
      type:'Focal Length',
      value:'17-50mm'
    },
    {
      type:'Maximum Aperture',
      value:'F4'
    },
    {
      type:'Angle of View (diagonal)',
      value:'103°41′- 46°48′　(for full-frame mirrorless format)'
    },
    {
      type:'Optical Construction',
      value:'15 elements in 13 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.19m / 7.5in (WIDE) / 0.3m / 11.8in (TELE)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:4.6 (WIDE) / 1:3.8 (TELE)'
    },
    {
      type:'Filter Size',
      value:'Ø67mm'
    },
    {
      type:'Maximum Diameter',
      value:'Ø74.8mm'
    },
    {
      type:'Length*',
      value:'114.4mm (4.5in)'
    },
    {
      type:'Weight**',
      value:'460g (16.2oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)'
    },
    {
      type:'Minimum Aperture',
      value:'F22'
    },
    
    {
      type:'Standard Accessories',
      value:'Flower-shaped hood, Lens caps'
    },
   
  ]
},
{
  id:'21',
  status:this.old_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a071_style_20200313.png?alt=media&token=02235603-6304-4be5-bcb2-30e9c0e4a50b',
  title: 'Tamron 28-200mm F/2.8-5.6 Di III RXD',
  model: 'Model A071',
  detailsLink: 'simple-product/21',
  shortDescription:'The world’s first*1 F2.8 all-in-one zoom lens for full-frame mirrorless cameras.',
  descriptionList:[
    'Light weight and compact size for superior mobility.',
    'Uncompromising image quality that lets you focus on creativity.',
    'Superior up-close shooting performance.',
    'The RXD stepping motor unit is exceptionally quiet.',
    'A full family of superb, compact lenses made especially for mirrorless cameras.',
    'Moisture-Resistant Construction, Fluorine Coating, and Zoom Lock switch.',
    'Compatible with main camera-specific features and functions. <br>Tamron’s new 28-200mm F2.8-5.6 is compatible with many of the advanced features that are specific to certain mirrorless cameras. These include the following:<br>- Fast Hybrid AF <br>- Eye AF <br>- Direct Manual Focus (DMF)<br>- In-camera lens correction (shading, chromatic aberration, distortion)<br>- Camera-based lens unit firmware updates',

  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'A071'
    },
    {
      type:'Focal Length',
      value:'28‐200mm'
    },
    {
      type:'Maximum Aperture',
      value:'F2.8-5.6'
    },
    {
      type:'Angle of View (diagonal)',
      value:'75°23\'-12°21\'　 (for full-frame mirrorless format)'
    },
    {
      type:'Optical Construction',
      value:'18 elements in 14 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.19m /7.5 in (WIDE), 0.8m /31.5 in (TELE)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:3.1 (WIDE) / 1:3.8 (TELE)'
    },
    {
      type:'Filter Size',
      value:'φ67mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ74mm'
    },
    {
      type:'Length*',
      value:'117mm (4.6 in)'
    },
    {
      type:'Weight',
      value:'575g (20.3 oz)'
    },
    {
      type:'Aperture Blades',
      value:'7 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F16-32'
    },
    {
      type:'Standard Accessories',
      value:'Flower-shaped hood, Lens caps'
    },
   
  ]
},
{
  id:'22',
  status:this.old_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/f050_style_20190918.png?alt=media&token=00e57962-cfc9-4610-950e-05638d51b460',
  title: 'Tamron 20mm F/2.8 Di III OSD M1:2',
  model: 'Model F050',
  detailsLink: 'simple-product/22',
  shortDescription:'Enhanced close-focusing capability expands lens usefulness and versatility.',
  descriptionList:[
    'A compact, lightweight 67mm filter diameter system offering excellent portability.',
    'Superb high-resolution performance that matches the latest high-resolution image sensors.',
    'Consistent 64mm (2.5 in) overall length facilitates ease-of-use.',
    'Silent autofocus driven by OSD (Optimized Silent Drive) DC motor.',
    'Moisture-Resistant Construction and Fluorine Coating.',
    'Compatible with main camera-specific features and functions.<br>All three of Tamron’s new fixed focal lenses are compatible with many of the advanced features that are specific to certain mirrorless cameras. These include the following:<br>-Fast Hybrid AF<br>-Eye AF<br>-Direct Manual Focus (DMF)<br>-In-camera lens correction (shading, chromatic aberration, distortion)<br>Camera-based lens unit firmware updates'

  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'F050'
    },
    {
      type:'Focal Length',
      value:'20mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'94°30'
    },
    {
      type:'Optical Construction',
      value:'10 elements in 9 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.11m (4.3 in)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:2'
    },
    {
      type:'Filter Size',
      value:'Ø67mm'
    },
    {
      type:'Maximum Diameter',
      value:'Ø73mm'
    },
    {
      type:'Length*',
      value:'64mm'
    },
    {
      type:'Weight',
      value:'220g (7.8 oz)'
    },
    {
      type:'Aperture Blades',
      value:'7 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F22'
    },
    {
      type:'Standard Accessories',
      value:'Lens hood (Flower-shaped), Lens caps'
    },
   
  ]
},
{
  id:'23',
  status:this.old_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/f051_style_20190918.png?alt=media&token=3e29a3d6-190b-4dc6-82d7-579d002569cb',
  title: 'Tamron 24mm F/2.8 Di III OSD M1:2',
  model: 'Model F051',
  detailsLink: 'simple-product/23',
  shortDescription:'Enhanced close-focusing capability expands lens usefulness and versatility.',
  descriptionList:[
    'A compact, lightweight 67mm filter diameter system offering excellent portability.',
    'Superb high-resolution performance that matches the latest high-resolution image sensors.',
    'Consistent 64mm (2.5 in) overall length facilitates ease-of-use.',
    'Silent autofocus driven by OSD (Optimized Silent Drive) DC motor.',
    'Moisture-Resistant Construction and Fluorine Coating.',
    'Compatible with main camera-specific features and functions.',
    'All three of Tamron’s new fixed focal lenses are compatible with many of the advanced features that are specific to certain mirrorless cameras. These include the following:<br>-Fast Hybrid AF<br>-Eye AF<br>-Direct Manual Focus (DMF)<br>-In-camera lens correction (shading, chromatic aberration, distortion)<br>-Camera-based lens unit firmware updates'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'F051'
    },
    {
      type:'Focal Length',
      value:'24mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'84°04\''
    },
    {
      type:'Optical Construction',
      value:'10 elements in 9 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.12m (4.7 in)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:2'
    },
    {
      type:'Filter Size',
      value:'Ø67mm'
    },
    {
      type:'Maximum Diameter',
      value:'Ø73mm'
    },
    {
      type:'Length*',
      value:'64mm'
    },
    {
      type:'Weight',
      value:'215g (7.6 oz)'
    },
    {
      type:'Aperture Blades**',
      value:'7 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F22'
    },
    {
      type:'Standard Accessories',
      value:'Lens hood (Flower-shaped), Lens caps'
    },
   
  ]
},
{
  id:'24',
  status:this.old_status,
  type:'Mirrorless_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/f053_style_201909189.png?alt=media&token=1a1583b9-582a-4afc-9eab-c44cb166e2c2',
  title: 'Tamron 35mm F/2.8 Di III OSD M1:2',
  model: 'Model F053',
  detailsLink: 'simple-product/24',
  shortDescription:'1.	Enhanced close-focusing capability expands lens usefulness and versatility.',
  descriptionList:[
    'A compact, lightweight 67mm filter diameter system offering excellent portability.',
    'Superb high-resolution performance that matches the latest high-resolution image sensors.',
    'Consistent 64mm (2.5 in) overall length facilitates ease-of-use.',
    'Silent autofocus driven by OSD (Optimized Silent Drive) DC motor.',
    'Moisture-Resistant Construction and Fluorine Coating.',
    '7.	Compatible with main camera-specific features and functions.<br>All three of Tamron’s new fixed focal lenses are compatible with many of the advanced features that are specific to certain mirrorless cameras. These include the following:<br>-Fast Hybrid AF<br>-Eye AF<br>-Direct Manual Focus (DMF)<br>-In-camera lens correction (shading, chromatic aberration, distortion)<br>-Camera-based lens unit firmware updates'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'F053'
    },
    {
      type:'Focal Length',
      value:'35mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'63°26\''
    },
    {
      type:'Optical Construction',
      value:'9 elements in 8 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.15m (5.9 in)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:2'
    },
    {
      type:'Filter Size',
      value:'Ø67mm'
    },
    {
      type:'Maximum Diameter',
      value:'Ø73mm'
    },
    {
      type:'Length*',
      value:'64mm'
    },
    {
      type:'Weight',
      value:'210g (7.4 oz)'
    },
    {
      type:'Aperture Blades**',
      value:'7 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F22'
    },
    {
      type:'Standard Accessories',
      value:'Lens hood (Cap-type), Lens caps'
    },
   
  ]
},
{
  id:'25',
  status:this.old_status,
  type:'DSLR_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a032n_style_20170427.png?alt=media&token=ceaeed96-d09c-4d08-ba4a-755cb675ee08',
  title: 'Tamron SP 24-70mm F/2.8 Di VC USD G2 ',
  model: 'Model A032',
  detailsLink: 'simple-product/25',
  shortDescription:'Sophisticated design delivers superb performance and ultra-high image quality.',
  descriptionList:[
    'eBAND Coating provides superior anti-reflection properties, and reduces ghosting and flare.',
    'New Dual MPU (Micro-Processing Unit) control system*3 provides faster and more precise autofocus and improved Vibration Compensation.',
    'Fluorine Coating and Moisture-Resistant Construction.',
    'Lens hood equipped with new, convenient locking mechanism.',
    'Compatible with TAMRON TAP-in ConsoleTM, an optional accessory.',
    'Electromagnetic diaphragm system now used also for Nikon-mount lenses.',
    'Manufacturing innovation with thorough attention to details based on the rigorous quality standards worthy of the SP series.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'A032'
    },
    {
      type:'Focal Length',
      value:'24-70mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'84°04\'-34°21\' (for full-frame format) <br> 60°20\' -22°33\' (for APS-C format)'
    },
    {
      type:'Optical Construction',
      value:'17elements in 12 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.38m (15 in)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:5'
    },
    {
      type:'Filter Size',
      value:'φ82mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ88.4mm'
    },
    {
      type:'Length*',
      value:'For Canon 111mm (4.4 in)<br>For Nikon 108.5mm (4.3 in)'
    },
    {
      type:'Weight**',
      value:'For Canon 905g (31.9oz)<br>For Nikon 900g ( 31.7 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)'
    },
    {
      type:'Minimum Aperture',
      value:'F/22'
    },
    {
      type:'Image Stabilization Performance',
      value:'5 stops (CIPA Standards Compliant)'
    },
    {
      type:'Standard Accessories',
      value:'Lens hood, Lens caps, Lens pouch'
    },
   
  ]
},
{
  id:'26',
  status:this.old_status,
  type:'DSLR_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a034n_style_171020.png?alt=media&token=25640527-6605-44f8-8ffa-976411e24bfc',
  title: 'Tamron 70-210mm F/4 Di VC USD',
  model: 'Model A034',
  detailsLink: 'simple-product/26',
  shortDescription:'High-performance telephoto zoom lens with a constant maximum aperture of F/4.',
  descriptionList:[
    'Class-leading magnification ratio and MOD (Minimum Object Distance).',
    'Highly reliable internal zoom mechanism.',
    'High-speed Dual MPU (Micro-Processing Unit)* control system delivers responsive autofocus performance plus outstanding VC (Vibration Compensation) image stabilization.',
    'Fluorine Coating.',
    'Moisture-Resistant Construction.',
    'Compatible with Tamron tele converter.',
    'Compatible with TAMRON TAP-in ConsoleTM, an optional accessory.',
    'Optional tripod mount compatible with Arca-Swiss style quick release plates.',
    'Electromagnetic diaphragm system now used also for Nikon-mount lenses.'

  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'A034'
    },
    {
      type:'Focal Length',
      value:'70‐210mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/4'
    },
    {
      type:'Angle of View (diagonal)',
      value:'34°21\' -11°46\'　(for full-frame format)<br>	23°01\' -7°46\'　 (for APS-C format)'
    },
    {
      type:'Optical Construction',
      value:'20 elements in 14 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.95m (37.4 in)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:3.1'
    },
    {
      type:'Filter Size',
      value:'φ67mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ76mm'
    },
    {
      type:'Length*',
      value:'For Canon 176.5mm (6.9 in)<br> For Nikon 174mm (6.8 in)'
    },
    {
      type:'Weight**',
      value:'For Canon 860g　(30.3 oz) <br>For Nikon 850g (30 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)'
    },
    {
      type:'Minimum Aperture',
      value:'F/4-F/32'
    },
    {
      type:'Image Stabilization Performance',
      value:'4 stops (CIPA Standards Compliant)<br>For Canon : EOS-5D MKIII is used / For Nikon : D810 is used'
    },
    {
      type:'Standard Accessories',
      value:'Lens hood, Lens caps'
    },
   
  ]
},
{
  id:'27',
  status:this.old_status,
  type:'DSLR_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a035_style_nikon.png?alt=media&token=14c4452a-8e75-43af-8bd6-d3254f84b55e',
  title: 'Tamron 100-400mm F/4.5-6.3 Di VC USD',
  model: 'Model A035',
  detailsLink: 'simple-product/27',
  shortDescription:'High-speed Dual MPU (Micro-Processing Unit) control system delivers quick and highly responsive autofocus performance plus outstanding VC image stabilization',
  descriptionList:[
    'Superb image quality in an ultra-telephoto zoom lens.',
    'Exclusive eBAND Coating reduces flare and ghosting.',
    'Lightest weight, 1,115 g (39.3 oz) lens in the ultra-telephoto zoom lens class*, and only 196.5 mm (7.7 in) long.',
    'Optional accessory tripod mount is Arca-Swiss compatible.',
    'Compatible with tele converters and TAP-in Console.',
    'Moisture-Resistant Construction and Fluorine Coating for enhanced weather protection.',
    'Electromagnetic diaphragm system now used also for Nikon-mount lenses.',
    'External design places importance on functionality and ease of use.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'A035'
    },
    {
      type:'Focal Length',
      value:'100-400mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/4.5-6.3'
    },
    {
      type:'Angle of View (diagonal)',
      value:'24°24\' - 6°12‘ (for full-frame format)<br>15°54\' - 4°01‘ (for APS-C format)'
    },
    {
      type:'Optical Construction',
      value:'17 elements in 11 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'1.5m ( 59 in)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:3.6'
    },
    {
      type:'Filter Size',
      value:'φ67mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ86.2mm'
    },
    {
      type:'Length**',
      value:'For Canon 199mm (7.8 in)<br>For Nikon 196.5mm (7.7 in)'
    },
    {
      type:'Weight',
      value:'for Canon 1,135g (40 oz)<br>For Nikon 1,115g (39.3 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)'
    },
    {
      type:'Minimum Aperture',
      value:'F/32-45'
    },
    {
      type:'Image Stabilization Performance',
      value:'4 stops (CIPA Standards Compliant) <br> (For Canon : EOS 5DMarkIII is used / For Nikon: D810 is used)'
    },
    {
      type:'Standard Accessories',
      value:'Lens hood, Lens caps'
    },
    {
      type:'Optional Accessories',
      value:'Optional Accessories	: Tripod mount, Teleconverter (1.4x, 2.0x), TAP-in ConsoleTM'
    },
   
  ]
},
{
  id:'28',
  status:this.old_status,
  type:'DSLR_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a041n_style_180724.png?alt=media&token=e70efeaa-b9be-41ef-9c5d-996f4833dc1d',
  title: 'Tamron SP 15-30mm F/2.8 Di VC USD G2 ',
  model: 'Model A041',
  detailsLink: 'simple-product/28',
  shortDescription:'Super high-quality high-speed ultra-wide-angle zoom lens.',
  descriptionList:[
    'Newly developed AX Coating.',
    'High-speed, high-precision AF.',
    'VC promises sharp images for all varieties of shooting.',
    'Rear filter holder.',
    'Vastly improved highly durable Fluorine Coating.',
    'The new design provides greater operability and design consistency.',
    'Compatible with TAMRON TAP-in ConsoleTM, an optional accessory.',
    'Moisture-Resistant Construction.',
    'Manufacturing innovation with thorough attention to details based on the rigorous quality standards worthy of the SP series.'

  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'A041'
    },
    {
      type:'Focal Length',
      value:'15‐30mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/2.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'110°32\' - 71°35\' (for full-frame DSLR cameras) <br>85°52\' - 49°54\' (for APS-C format DSLR cameras)'
    },
    {
      type:'Optical Construction',
      value:'18 elements in 13 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.28m (11 in) Full zoom range'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:5 (f=30mm)'
    },
    {
      type:'Filter Size',
      value:'N/A*'
    },
    {
      type:'Maximum Diameter',
      value:'φ98.4mm'
    },
    {
      type:'Length**',
      value:'For Canon 145mm (5.7 in)<br>For Nikon 142.5mm (5.6 in)'
    },
    {
      type:'Weight',
      value:'For Canon 1,110g (39.2 oz)<br>For Nikon 1,100g (38.8 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)***'
    },
    {
      type:'Minimum Aperture',
      value:'F/22'
    },
    {
      type:'Image Stabilization Performance',
      value:'4.5 stops (TELE) (CIPA Standards Compliant)<br>For Canon: EOS-5D MKIII is used / For Nikon: D810 is used'
    },
    {
      type:'Standard Accessories',
      value:'Integrated flower-shaped hood<br>Lens caps (push-on front, rear), Lens pouch'
    },
   
  ]
},
{
  id:'29',
  status:this.old_status,
  type:'DSLR_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a043n_style_190118.png?alt=media&token=c898afb8-611d-47bf-8d43-ae31c9a59cd4',
  title: 'Tamron 35-150mm F/2.8-4 Di VC OSD',
  model: 'Model A043',
  detailsLink: 'simple-product/29',
  shortDescription:'Superb optical performance meets requirements of high resolution 50+ megapixel DSLRs.',
  descriptionList:[
    'Outstanding performance even in strongly backlit situations.',
    'High-speed Dual MPU (Micro-Processing Unit) control system delivers fast and highly responsive autofocus performance plus outstanding vibration compensation.',
    'MOD (Minimum Object Distance) of 0.45m (17.7 in) across the entire zoom range.',
    'Next-generation design is consistent with brand identity and is ergonomically superb.',
    'Moisture-Resistant Construction',
    'Fluorine Coating',
    'Compatible with TAMRON TAP-in ConsoleTM, an optional accessory.',
    'Covers a broad range of diverse scenarios when combined as a set with the 17-35mm F/2.8-4 Di OSD (Model A037) ultra-wide angle zoom.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'A043'
    },
    {
      type:'Focal Length',
      value:'35‐150mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/2.8-4'
    },
    {
      type:'Angle of View (diagonal)',
      value:'63°26\' - 16°25\' (for full-frame DSLR cameras)'
    },
    {
      type:'Optical Construction',
      value:'19 elements in 14 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.45m (17.7 in) Full zoom range'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:3.7 (f=150mm)'
    },
    {
      type:'Filter Size',
      value:'φ77mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ84mm '
    },
    {
      type:'Length*',
      value:'For Canon 126.8mm (5 in)<br>For Nikon 124.3mm (4.9 in)'
    },
    {
      type:'Weight',
      value:'For Canon 796g (28.1 oz)<br>for Nikon 790g (27.9 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F/16-22'
    },
    {
      type:'Image Stabilization Performance',
      value:'5 stops (CIPA Standards Compliant)<br>For Canon: EOS-5D MKIII is used / For Nikon: D810 is used.'
    },
    {
      type:'Standard Accessories',
      value:'Flower-shaped hood, Lens caps'
    },
   
  ]
},
{
  id:'30',
  status:this.old_status,
  type:'DSLR_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/f012n_style_20150623.png?alt=media&token=072d833f-4a30-4ae8-8c1a-1d9ae8fa578a',
  title: 'Tamron SP 35mm F/1.8 Di VC USD',
  model: 'Model F012',
  detailsLink: 'simple-product/30',
  shortDescription:'Even when used wide open at F/1.8 aperture, provides outstanding image quality with maximum aberration compensation, thanks to optimal use of sophisticated glass materials and highly advanced optical design.',
  descriptionList:[
    '35mm focal length with fast F/1.8 maximum aperture and integral VC (Vibration Compensation) for full-frame DSLR cameras.',
    'MOD (Minimum Object Distance) of 0.2m (7.9”), the best-in-class close-focusing capability*',
    'Optimal relative illumination means no dark corners.',
    'eBAND Coating deployed to thoroughly suppress ghosting and flare.',
    'High-speed AF with USD (Ultrasonic Silent Drive).',
    'Fluorine coating on the front element repels water and fingerprints.',
    'Moisture-resistant construction for shooting outdoors under adverse weather conditions.',
    'Circular aperture for enhanced bokeh.',
    'Compatibility with Adobe and Silkypix software.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'F012'
    },
    {
      type:'Focal Length',
      value:'35mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/1.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'63°26\' (for full-frame format)<br>43°29\' (for APS-C format)'
    },
    {
      type:'Optical Construction',
      value:'10 elements in 9 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.2m (7.9 in)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:2.5'
    },
    {
      type:'Filter Size',
      value:'φ67mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ80.4mm'
    },
    {
      type:'Length*',
      value:'For Canon 80.8mm (3.2 in)<br>For Nikon 78.3mm (3.1 in)<br>For Sony 80.3mm (3.2 in)'
    },
    {
      type:'Weight',
      value:'For Canon 480g (16.9 oz)<br>For Nikon 450g (15.9 oz)<br>For Sony 450g (15.9 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm**)'
    },
    {
      type:'Minimum Aperture',
      value:'F/16'
    },
    
    {
      type:'Standard Accessories',
      value:'Flower-shaped lens hood, Lens caps'
    },
   
  ]
},
{
  id:'31',
  status:this.old_status,
  type:'DSLR_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/f013n_style_20150623.png?alt=media&token=a48a35d9-286c-4bd9-a5ad-4ed8847a6bbe',
  title: 'Tamron SP 45mm F/1.8 Di VC USD ',
  model: 'Model F013',
  detailsLink: 'simple-product/31',
  shortDescription:'Even when used wide open at F/1.8 aperture, provides outstanding image quality with maximum aberration compensation, thanks to optimal use of sophisticated glass materials and highly advanced optical design.',
  descriptionList:[
    'World’s first* full-frame 45mm focal length with fast F/1.8 maximum aperture and integral VC (Vibration Compensation).',
    'MOD (Minimum Object Distance) of 0.29m (11.4”), the best-in-class close focusing capability**.',
    'Optimal relative illumination means no dark corners.',
    'eBAND Coating deployed to thoroughly suppress ghosting and flare.',
    'High-speed AF with USD (Ultrasonic Silent Drive).',
    'Fluorine coating on the front element repels water and fingerprints.',
    'Moisture-resistant construction for shooting outdoors under adverse weather conditions.',
    'Circular aperture for enhanced bokeh.',
    'Compatibility with Adobe and Silkypix software.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'F013'
    },
    {
      type:'Focal Length',
      value:'45mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/1.8'
    },
    {
      type:'Angle of View (diagonal)',
      value:'51°21\' (for full-frame format)<br>34°28\' (for APS-C format)'
    },
    {
      type:'Optical Construction',
      value:'10 elements in 8 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.29m (11.4 in)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:3.4'
    },
    {
      type:'Filter Size',
      value:'φ67mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ80.4mm'
    },
    {
      type:'Length*',
      value:'For Canon 91.7mm (3.6 in)<br>For Nikon 89.2mm (3.5 in)<br>For Sony 91.6mm (3.6 in)'
    },
    {
      type:'Weight',
      value:'For Canon 540g (19 oz)<br>For Nikon 520g (18.3 oz)<br>For Sony 515g (18.2 oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm**)'
    },
    {
      type:'Minimum Aperture',
      value:'F/16'
    },
    {
      type:'Standard Accessories',
      value:'Flower-shaped lens hood, Lens caps'
    },
   
  ]
},
{
  id:'32',
  status:this.old_status,
  type:'DSLR_Full-frame',
  mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/f045n_style_with_lens_190424.png?alt=media&token=0898fae1-156f-45b2-9a7b-44ecf32fac6e',
  title: 'Tamron SP 35mm F/1.4 Di USD',
  model: 'Model F045',
  detailsLink: 'simple-product/32',
  shortDescription:'Consummate image quality across the entire frame from edge-to-edge.',
  descriptionList:[
    'Next-generation BBAR-G2 Coating.',
    'Exceptionally reliable, fast and accurate AF.',
    'Vastly improved highly durable Fluorine Coating.',
    'Moisture-Resistant Construction.',
    'Compatible with TAMRON TAP-in ConsoleTM, an optional accessory.',
    'Locking lens hood for precise positioning.',
    'Consistent with Tamron’s sophisticated design concepts.'
  ],
  description:'',
  specifications:[
    {
      type:'Model',
      value:'F045'
    },
    {
      type:'Focal Length',
      value:'35mm'
    },
    {
      type:'Maximum Aperture',
      value:'F/1.4'
    },
    {
      type:'Angle of View (diagonal)',
      value:'63°26\' (for full-frame DSLR cameras)'
    },
    {
      type:'Optical Construction',
      value:'14 elements in 10 groups'
    },
    {
      type:'Minimum Object Distance',
      value:'0.3m (11.8 in)'
    },
    {
      type:'Maximum Magnification Ratio',
      value:'1:5'
    },
    {
      type:'Filter Size',
      value:'φ72mm'
    },
    {
      type:'Maximum Diameter',
      value:'φ80.9mm '
    },
    {
      type:'Length*',
      value:'For Canon 104.8mm (4.1 in)<br>For Nikon 102.3mm (4 in)'
    },
    {
      type:'Weight',
      value:'For Canon 815g (28.7 oz)<br>For Nikon 805g (28.4oz)'
    },
    {
      type:'Aperture Blades',
      value:'9 (circular diaphragm)**'
    },
    {
      type:'Minimum Aperture',
      value:'F/16'
    },
    {
      type:'Standard Accessories',
      value:'Flower-shaped hood, Lens caps, Lens pouch'
    },
   
  ]
}

]
}

/*
{
            id:'13',
            type:'Mirrorless_Full-frame',
            mainImg: '',
            title: '',
            model: '',
            detailsLink: 'simple-product/13',
            shortDescription:'',
            descriptionList:[],
            description:'',
            specifications:[
              {
                type:'Model',
                value:''
              },
              {
                type:'Focal Length',
                value:''
              },
              {
                type:'Maximum Aperture',
                value:''
              },
              {
                type:'Angle of View (diagonal)',
                value:''
              },
              {
                type:'Optical Construction',
                value:''
              },
              {
                type:'Minimum Object Distance',
                value:''
              },
              {
                type:'Maximum Magnification Ratio',
                value:''
              },
              {
                type:'Filter Size',
                value:''
              },
              {
                type:'Maximum Diameter',
                value:''
              },
              {
                type:'Length*',
                value:''
              },
              {
                type:'Weight**',
                value:''
              },
              {
                type:'Aperture Blades',
                value:''
              },
              {
                type:'Minimum Aperture',
                value:''
              },
              {
                type:'Image Stabilization Performance',
                value:''
              },
              {
                type:'Standard Accessories',
                value:''
              },
             
            ]
        }
        */


/*
specifications:[
          {
            type:'Model',
            value:''
          },
          {
            type:'Focal Length',
            value:''
          },
          {
            type:'Maximum Aperture',
            value:''
          },
          {
            type:'Angle of View (diagonal)',
            value:''
          },
          {
            type:'Optical Construction',
            value:''
          },
          {
            type:'Minimum Object Distance',
            value:''
          },
          {
            type:'Maximum Magnification Ratio',
            value:''
          },
          {
            type:'Filter Size',
            value:''
          },
          {
            type:'Maximum Diameter',
            value:''
          },
          {
            type:'Length*',
            value:''
          },
          {
            type:'Weight**',
            value:''
          },
          {
            type:'Aperture Blades',
            value:''
          },
          {
            type:'Minimum Aperture',
            value:''
          },
          {
            type:'Image Stabilization Performance',
            value:''
          },
          {
            type:'Standard Accessories',
            value:''
          },
         
        ]
        */
