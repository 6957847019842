


<div class="section-title" >
    <div class="logo">
        <a routerLink="/"><img src="assets/img/TamronLogo.jpg" alt="logo" style="height: 100px;"></a>
    </div>
    <h1 style="padding-top: 20px;">Login</h1>
    
    
</div>

<section class="order-tracking-area">
    <div class="container">
        <div class="order-tracking-content">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="form-group">
                    <label>Enter Email</label>
                    <input required minlength="3" maxlength="50" ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                </div>

                <div class="form-group">
                    <label>Enter Password</label>
                    <input required ngModel name="password" type="password" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                </div>

                <button type="submit" class="default-btn">LOGIN</button>
            </form>
        </div>
    </div>
</section>

<!--section class="contact-area" >
    <div class="container" >
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-3 col-md-3"></div>
                <div class="col-lg-6 col-md-6">
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row" style="background-color: rgb(138, 201, 201);">
                                <h2 style="padding-top: 20px;text-align: center;">Login</h2>
                                <div class="col-lg-12 col-md-12" style="padding-top: 20px;" >
                                    <div class="form-group mb-3">
                                        
                                        <input required minlength="3" maxlength="50" ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Enter Email">
                                       
                                    </div>
                                </div>
                             

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="password" type="text" #password="ngModel" class="form-control" id="password" placeholder="Enter Password">
                                        
                                    </div>
                                </div>
                            
                                <div class="col-lg-4 col-md-4"></div>

                                <div class="col-lg-4 col-md-4" style="padding-bottom: 20px;">
                                    <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> LOGIN</button>
                                </div>
                                <div class="col-lg-4 col-md-4"></div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3"></div>
              
            </div>
        </div>
    </div>
</section-->

<script async src="https://www.googletagmanager.com/gtag/js?id=G-C8F6CSWD62"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-C8F6CSWD62');
</script>
