
<app-navbar-style-one></app-navbar-style-one>

<div class="section-title">
    <h2>Add Product</h2>
    
</div>

<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm">
                            <div class="row">
                                <div class="col-lg-8 col-md-8">
                                    <div class="form-group mb-3">
                                        <input required minlength="3" maxlength="50" ngModel name="productTitle" type="text" #productTitle="ngModel" class="form-control" id="productTitle" placeholder="Product Title">
                                       
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <input type="file" class="file-input" #fileUploadVehicleReg required accept=".jpg, .jpeg, .png"
                                        (change)="onFileSelectedVehicleReg($event)">
                                    </div>

                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-8">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="productDesciption" type="text" #productDesciption="ngModel" class="form-control" id="productDesciption" placeholder="Product Desciption">
                                        
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="productModel" type="text" #productModel="ngModel" class="form-control" id="productModel" placeholder="Product Model">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                      <p><b>Add Product Highlight</b></p>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-8">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="productHighlight" type="text" [(ngModel)]="productHighlights" class="form-control" id="productHighlight" placeholder="Add Product Highlight">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <button class="default-btn" (click)="addToProductHightlightList()"><i class='bx bx-plus'></i>Add To List</button>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <table class="table table-striped">
                                        <tbody  *ngFor="let highlight of productHighlightList;">
                                            <tr>
                                                <td>{{highlight}}</td>
                                               
                                                <td><button (click)="deleteHighlight(highlight)"><i class='bx bx-trash'></i></button></td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                    <!--ul *ngFor="let Content of productHighlightList;" >
                                        <li [innerHTML]="Content">{{Content}}  </li>
                                    </ul-->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                      <p><b>Add Product Specifications</b></p>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="specificationType" type="text" [(ngModel)]="specificationType" class="form-control" id="specificationType" placeholder="Add Specification Type">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="specificationValue" type="text" [(ngModel)]="specificationValue" class="form-control" id="specificationValue" placeholder="Add Specification Value">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <button  class="default-btn" (click)="addSpecification()"><i class='bx bx-plus'></i>Add Specification</button>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <table class="table table-striped">
                                        <tbody  *ngFor="let speccification of productSpecificationList;">
                                            <tr>
                                                <td>{{speccification.type}}</td>
                                                <td>: {{speccification.value}}</td>
                                                <td><button (click)="deleteSpedification(speccification)"><i class='bx bx-trash'></i></button></td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" (click)="submit(contactForm.value)" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> Save Product</button>
                                </div>
                            </div>      
                            
                        </form>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
</section>
