import { Component, OnInit } from '@angular/core';
import { LoginServiceService } from 'src/app/service/login-service.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-shop-right-sidebar-page-one',
	templateUrl: './shop-right-sidebar-page-one.component.html',
	styleUrls: ['./shop-right-sidebar-page-one.component.scss']
})
export class ShopRightSidebarPageOneComponent implements OnInit {

    constructor(private loginService:LoginServiceService) { }

    submit(form){
        
        var email = form.email;
        console.log(email);
        
        var password = form.password;
        console.log(password);

       
        
        let details = {
            'email':email,
            'password':password,
           
        }

        const uploadFormData = new FormData();
        
        uploadFormData.append('details', JSON.stringify(details));
       this.loginService.loginAdmin(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Success!',
                  }).then((result) => {
                    var token = res.token;
                    localStorage.setItem('token',token);
                    window.location.href = "/admin/home";
                  })
            }else{
                if(status == 'invalidPassword'){
                    Swal.fire({
                        title: 'Invalid Password',
                      }).then((result) => {
                        location.reload();
                      })
                }else{
                    Swal.fire({
                        title: 'Invalid User',
                      }).then((result) => {
                        location.reload();
                      })
                }
                
            }
        })
       


    }

    ngOnInit(): void {
       
        localStorage.setItem('token','');
        localStorage.clear();
     
    }

  

 

}