import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductListService } from 'src/app/service/product-list.service';
import { ProductServiceService } from 'src/app/service/product-service.service';

@Component({
    selector: 'app-trending-products',
    templateUrl: './trending-products.component.html',
    styleUrls: ['./trending-products.component.scss']
})
export class TrendingProductsComponent implements OnInit {

    singleProductsBox=[];
    constructor(private productService:ProductServiceService) {
        this.productService.getNewProductsByStatus(productService.trending_status).subscribe(res=>{
            this.singleProductsBox = res;
        })
       
     }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "Trending This Week",
            paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
        }
    ]
    /*singleProductsBox = [
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b070_style_8_20201019.png?alt=media&token=b6954daa-73fe-4f0d-ab51-856ffd0b8314',
            title: 'Tamron 17-70mm F/2.8 Di III-A VC RXD',
            newPrice: 'Model B070',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/1'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a047_style_8_200727.png?alt=media&token=7741a38a-e852-483c-a1a6-f38ead6c2e1a',
            title: 'Tamron 70-300mm F/4.5-6.3 Di III RXD',
            newPrice: 'Model A047',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/2'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b018n_style_20150525.png?alt=media&token=763fe1ba-dab8-49b7-9478-c5f7b42cef33',
            title: 'TAMRON 18-200mm F/3.5-6.3 DI II VC ',
            newPrice: 'Model B018',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/3'
        },
       
    ]*/

    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }

}