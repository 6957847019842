<!-- Start Top Header Area -->
 <!--ul class="top-header-contact-info">
                    <li><a href="+44458895456"><i class='bx bx-phone-call'></i> +44 458 895 456</a></li>
                    <li><a href="mailto:hello&#64;fiwan.com"><i class='bx bx-envelope'></i> hello&#64;fiwan.com</a></li>
                </ul-->
<!--section class="top-header-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
               
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="top-header-right">
                    <ul class="d-flex align-items-center justify-content-end">
                        <li>
                            <div class="dropdown language-switcher d-inline-block">
                               <img src="assets/img/flag/us.jpg" class="shadow" alt="image">
                                    <span>SL </span>
                               
                            </div>
                        </li>
                      
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section-->
  <!--li><a routerLink="/profile-authentication"><i class='bx bx-log-in'></i> Sign In</a></li>
                        <li><a routerLink="/profile-authentication"><i class='bx bx-user'></i> Sign Up</a></li>
                        -->

 <!--button class="dropdown-toggle" type="button" (click)="toggleClass()">
                                    <img src="assets/img/flag/us.jpg" class="shadow" alt="image">
                                    <span>Eng <i class='bx bx-chevron-down'></i></span>
                                </button-->
                                <!--div class="dropdown-menu" [class.active]="classApplied">
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/germany.jpg" class="shadow-sm" alt="flag">
                                        <span>Ger</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/france.jpg" class="shadow-sm" alt="flag">
                                        <span>Fre</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/spain.jpg" class="shadow-sm" alt="flag">
                                        <span>Spa</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/russia.jpg" class="shadow-sm" alt="flag">
                                        <span>Rus</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/italy.jpg" class="shadow-sm" alt="flag">
                                        <span>Ita</span>
                                    </a>
                                </div-->
<!-- End Top Header Area -->

<!-- Start Navbar Area -->
<div class="navbar-area" >
    <div class="row">
        <div class="col-10">
            <div class="fiwan-responsive-nav">
                <div class="container">
                    <div class="fiwan-responsive-menu">
                        <div class="logo">
                            <a routerLink="/"><img src="assets/img/TamronLogo.jpg" alt="logo"></a>
                        </div>
                    </div>
                </div>
            </div>


      

   

    <div class="fiwan-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/TamronLogo.jpg" alt="logo" style="width: 200px;"></a>
               
               
                
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>
                            <!--ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 1</a></li>

                                <li class="nav-item"><a routerLink="/index-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>

                                <li class="nav-item"><a routerLink="/index-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 3</a></li>
                            </ul-->
                        </li>
                     

                        <li class="nav-item"><a routerLink="/all-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products</a>
                            <!--ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/simple-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Simple Product</a></li>

                                <li class="nav-item"><a routerLink="/slider-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Slider Product</a></li>

                                <li class="nav-item"><a routerLink="/sticky-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sticky Product</a></li>
                            </ul-->
                        </li>

                        <li class="nav-item"><a routerLink="/news" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News</a>
                            <!--ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/shop-left-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Latest Updates </a>   
                                </li>
                                <li class="nav-item"><a routerLink="/shop-left-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Friends of Thamron</a>   
                                </li>

        
                            </ul-->
                        </li>
                            
                        <!--li class="nav-item"><a routerLink="/dealers-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dealers List</a></li>
                        <li class="nav-item"><a routerLink="/warrenty" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Warrenty</a></li-->

                        <!--li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error-404" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul>
                        </li-->

                        <!--li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Blog</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                                        
                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                            <i class='bx bx-search' ></i>
                        </li-->
                        
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                        <li class="nav-item"><a routerLink="/search" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" style="color: rgb(74, 174, 241);">Search</a></li>
                        <!--li class="nav-item">
                            <div class="align-items-center">
                                <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"> 
                                    <div class="others-option d-flex align-items-center">
                                    <div class="option-item">
                                    <div class="search-box" (click)="toggleSearchOverlay()" >
                                        <i class='bx bx-search'></i>
                                    </div>
                                    </div>
                                </div>
                                </a>
                            </div>
                        </li-->
                      
                        <!--li class="nav-item">
                          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"> 
                            <div class="others-option d-flex align-items-center">
                                <div class="option-item">
                                    <div class="search-box" (click)="toggleSearchOverlay()" >
                                        <i class='bx bx-search'></i>
                                    </div>
                                </div>
                            </div>
                           </a>  
                        </li-->
                       
                              
                      
                         
                    </ul>
                    <!--div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <div class="search-box" (click)="toggleSearchOverlay()" >
                                <i class='bx bx-search' style="color: black;"></i>
                            </div>
                        </div>
                    </div-->
                    <!--div class="others-option d-flex align-items-center"-->
                       
                    <!--/div-->

                   
                </div>
            </nav>
        </div>
    </div>

</div>
<div class="col-2">
    <div class="others-option d-flex align-items-center" style="padding-left: 5px;padding-top: 20px;">
    <div class="option-item">
        <img src="assets/img/flag/us.jpg" class="shadow" alt="image" >
        <span> Sri Lanka </span>
      </div>
    </div>
</div>
</div>
    

  
</div>
<!-- End Navbar Area -->

<!-- Search Overlay -->
<!--div class="search-overlay" [class.active]="isSearchOverlayVisible">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-close" (click)="closeSearchOverlay()">
                <span class="search-overlay-close-line" style="background-color: black;"></span>
                <span class="search-overlay-close-line" style="background-color: black;"></span>
            </div>
            <div class="search-overlay-form" >
                <form>
                    <input type="text" class="input-search" placeholder="Search here..." name="search_value" [(ngModel)]="searchValue" style="background-color: blue;">
                    <button type="submit" [routerLink]="['/search-product', searchValue]" (click)="toggleSearchOverlay();"><i class='bx bx-search'></i></button>
                    
                    
                </form>
                
                
            </div>
        </div>
    </div>
</div-->
<!-- End Search Overlay  [routerLink]="['/search-product', searchValue]" -->