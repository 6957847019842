<app-navbar-style-two></app-navbar-style-two>

<app-hometwo-banner></app-hometwo-banner>

<!--app-partner></app-partner-->

<section class="products-area pt-100 pb-70">
    <app-new-products></app-new-products>
</section>

<!--app-deal-in-this-week></app-deal-in-this-week-->

<div class="products-area ptb-100">
    <app-trending-products></app-trending-products>
</div>

<!--app-facility-style-two></app-facility-style-two-->

<div class="products-area ptb-100">
    <app-popular-products></app-popular-products>
</div>

<!--app-testimonials></app-testimonials-->

<app-blog></app-blog>
<script async src="https://www.googletagmanager.com/gtag/js?id=G-C8F6CSWD62"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-C8F6CSWD62');
</script>

<!--app-subscribe></app-subscribe-->