import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-bedroom-products',
    templateUrl: './bedroom-products.component.html',
    styleUrls: ['./bedroom-products.component.scss']
})
export class BedroomProductsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

   

}