import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendServiceService } from './backend-service.service';

@Injectable({
  providedIn: 'root'
})
export class NewsServiceService {

  constructor(private httpClient:HttpClient,private backendService:BackendServiceService) { }

  saveDetails(details:any){
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'news/addnews',details);
  }

  getAllDetails(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'news/getallnews');
   
  }

  getNewsDetailsById(newsId:any){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'news/getnewsdetilsbyid?id='+newsId);
  }

  editImage(details:any){
    
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'news/editnewsimage',details);
  }

  editDetails(details:any){
    
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'news/editnewsdetails',details);
  }
}
