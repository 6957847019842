import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductListService } from 'src/app/service/product-list.service';

@Component({
    selector: 'app-popular-products',
    templateUrl: './popular-products.component.html',
    styleUrls: ['./popular-products.component.scss']
})
export class PopularProductsComponent implements OnInit {

    singleProductsBox=[];
    constructor(private productService:ProductListService) {
        this.singleProductsBox = productService.getProductsByStatus(productService.popular_status);
     }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "Popular Products",
            paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
        }
    ]
    /*singleProductsBox = [
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b023n_style_20170111.png?alt=media&token=12c18659-bc87-40f3-a0fd-46d3cbc7e23c',
            title: 'Tamron 10-24mm F/3.5-4.5 Di II VC HLD',
            newPrice: 'Model B023',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/1'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b028n_style_20170313.png?alt=media&token=eec95047-92d6-471d-86b1-6975be581034',
            title: 'Tamron 18-400mm F/3.5-4.5 Di II VC HLD ',
            newPrice: 'Model B028',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/2'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a010n_style_20140619.png?alt=media&token=ef281b07-d88c-4b5d-88cd-c085cb79a6ad',
            title: 'TAMRON 28-300mm F/3.5-6.3 Di VC PZD',
            newPrice: 'Model A010',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/3'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b060_style_8_20210226.png?alt=media&token=ae9a0d45-7fba-48e2-aa48-9feaf7dd13ab',
            title: 'TAMRON 11-20mm F/2.8 Di III-A RXD',
            newPrice: 'Model B060',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/4'
        },
    ]*/

    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }

}