<!-- Start Main Banner Area -->
<div class="home-slides-two">
    <owl-carousel-o [options]="homeSlidesOptions">
        <ng-template carouselSlide *ngFor="let Content of mainBannerItem;">
            <div class="banner-area">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-12">
                            <div class="banner-content">
                                <h1>{{Content.title}}</h1>
                                <p>{{Content.shortDescription}}</p>
                               
                                <a routerLink="/{{Content.detailsLink}}" class="default-btn"><i class="bx bx-show-alt"></i> View</a>
                            </div>
                        </div>
        
                        <div class="col-lg-8 col-md-12">
                            <div>
                                <img [src]="Content.mainImg" alt="image" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
<!-- End Main Banner Area -->