
<div class="navbar-area" >
    <div class="row">
        <div class="col-10">
            <div class="fiwan-responsive-nav">
                <div class="container">
                    <div class="fiwan-responsive-menu">
                        <div class="logo">
                            <a routerLink="/"><img src="assets/img/TamronLogo.jpg" alt="logo"></a>
                        </div>
                    </div>
                </div>
            </div>


      

   

    <div class="fiwan-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/TamronLogo.jpg" alt="logo" style="width: 200px;"></a>
               
               
                
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/admin/home" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>

                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Products</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/admin/view-all-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">View All Products</a></li>
                                <li class="nav-item"><a routerLink="/admin/add-product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Add Products</a></li>
                            </ul>
                        </li>
                     

                        <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">News</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/admin/view-all-news" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">View All News</a></li>
                                <li class="nav-item"><a routerLink="/admin/add-news" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Add News</a></li>  
                            </ul>
                        </li>

                        <li class="nav-item"><a (click)="logOutClicked()" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" style="color: rgb(37, 63, 181);">Logout</a>
                        </li>

                        <!--li class="nav-item"><a routerLink="/dealers-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dealers List</a></li>
                        <li class="nav-item"><a routerLink="/warrenty" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Warrenty</a></li-->  
                         
                    </ul> 
                </div>
            </nav>
        </div>
    </div>

</div>
<div class="col-2">
    <!--div class="others-option d-flex align-items-center" style="padding-left: 5px;padding-top: 20px;">
    <div class="option-item">
        <img src="assets/img/flag/us.jpg" class="shadow" alt="image" >
        <span> Sri Lanka </span>
      </div>
    </div-->
</div>
</div>
    

  
</div>
<!-- End Navbar Area -->

<!-- Search Overlay -->
<!--div class="search-overlay" [class.active]="isSearchOverlayVisible">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-close" (click)="closeSearchOverlay()">
                <span class="search-overlay-close-line" style="background-color: black;"></span>
                <span class="search-overlay-close-line" style="background-color: black;"></span>
            </div>
            <div class="search-overlay-form" >
                <form>
                    <input type="text" class="input-search" placeholder="Search here..." name="search_value" [(ngModel)]="searchValue" style="background-color: blue;">
                    <button type="submit" [routerLink]="['/search-product', searchValue]" (click)="toggleSearchOverlay();"><i class='bx bx-search'></i></button>
                    
                    
                </form>
                
                
            </div>
        </div>
    </div>
</div-->
<!-- End Search Overlay  [routerLink]="['/search-product', searchValue]" -->