import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {

    constructor(private router: Router) {
        this.classApplied=false;
      }
 
     ngOnInit(): void {
     }
 
     classApplied = false;
     
     toggleClass() {
         this.classApplied = !this.classApplied;
     }
 
     searchValue: string = '';
 
      searchClicked(){
         this.router.navigate(['/search-product', this.searchValue]);
        
      }
 
 
      isSearchOverlayVisible = true;
 
   toggleSearchOverlay() {
     console.log("toggle search clicked ");
     this.isSearchOverlayVisible = !this.isSearchOverlayVisible;
   }
 
   closeSearchOverlay() {
     this.isSearchOverlayVisible = false;
   }
 

}