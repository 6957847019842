import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendServiceService } from './backend-service.service';

@Injectable({
  providedIn: 'root'
})
export class ProductServiceService {

  trending_status= 'TRENDING';
  new_status = 'NEW';
  old_status = 'OLD';
  popular_status = 'POPULAR';


  constructor(private httpClient:HttpClient,private backendService:BackendServiceService) { }

  saveDetails(details:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'system/admin/product/addproduct?mds='+token,details);
  }

  getAllDetails(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getallproduct');
   
  }

  getProductDetailsById(itemId:any){

    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getproductdetilsbyid?id='+itemId);
  }

 

  getNewProducts(){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getnewproducts');
  }

  getNewProductsByStatus(status){
    return this.httpClient.get<any>(this.backendService.apiLocalUrl+'product/getproductsbystatus?status='+status);
  }

  editImage(details:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'system/admin/product/editproductimage?mds='+token,details);
  }

  editDetails(details:any){
    var token = localStorage.getItem('token');
    return this.httpClient.post<any>(this.backendService.apiLocalUrl+'system/admin/product/editproductdetails?mds='+token,details);
  }
}
