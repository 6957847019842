import { Component, OnInit } from '@angular/core';
import { NewsServiceService } from 'src/app/service/news-service.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-my-account-page',
    templateUrl: './my-account-page.component.html',
    styleUrls: ['./my-account-page.component.scss']
})
export class MyAccountPageComponent implements OnInit {

    constructor(private newsService:NewsServiceService) { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Profile Authentication'
        }
    ]

    submit(form){
        
        var newsTitle = form.newsTitle;
        console.log(newsTitle);
        
        var newsDesciption = form.newsDesciption;
        console.log(newsDesciption);

       
        
        let details = {
            'newsTitle':newsTitle,
            'newsDesciption':newsDesciption,
           
        }

        const uploadFormData = new FormData();
        uploadFormData.append('file', this.selectedFileVehicelReg);
        uploadFormData.append('details', JSON.stringify(details));
        this.newsService.saveDetails(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Successfully added the news!',
                  }).then((result) => {
                    location.reload();
                  })
            }else{
                Swal.fire({
                    title: 'Error with adding the news!',
                  }).then((result) => {
                    location.reload();
                  })
            }
        })
       


    }

    fileSizeInBytes:any;
    public selectedFileVehicelReg: any = File;
    onFileSelectedVehicleReg(event: any) {
      const file = event.target.files[0];
      this.fileSizeInBytes = file.size;
      if(this.fileSizeInBytes>10485760){
        Swal.fire({
          title: 'Size of image should be less than 10240kb!',
        }).then((result) => {
        })
      }else{
        this.selectedFileVehicelReg = file;
      }
     
  
  
    }

}