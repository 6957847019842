import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductListService } from 'src/app/service/product-list.service';
import { ProductServiceService } from 'src/app/service/product-service.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-shop-full-width-page-two',
    templateUrl: './shop-full-width-page-two.component.html',
    styleUrls: ['./shop-full-width-page-two.component.scss']
})
export class ShopFullWidthPageTwoComponent implements OnInit {

    itemId: string;
    constructor(private route: ActivatedRoute,private itemDetails:ProductListService,private productService:ProductServiceService) { }
    title :string;
    selectedDetails :any;
    itemStatus:any;
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.itemId = params['itemId']; 
            console.log("product_id "+this.itemId);
            this.productService.getProductDetailsById(this.itemId).subscribe(res=>{
                const selectedItem = res;
                this.selectedDetails = selectedItem;
                console.log("proooo "+JSON.stringify(selectedItem));
                this.productHighlightList = selectedItem.descriptionList;
                this.productSpecificationList = selectedItem.specifications;
                this.itemStatus = selectedItem.status;
            })

          //  const selectedItem = this.itemDetails.getDetails(this.itemId);
           
           
          });

    }

    //Edit **************
    productHighlights:string='';
    specificationType:string='';

    specificationValue:string='';
    productSpecificationList:Array<any> =[];


    productHighlightEditIndex:any=-1;
    
    editHighlight(highlight:any){
      this.productHighlights = highlight;
      console.log("before edit index "+this.productHighlightEditIndex);
      this.productHighlightEditIndex = this.productHighlightList.indexOf(highlight);
      console.log("after edit index "+this.productHighlightEditIndex);

      /*const index = this.productHighlightList.indexOf(highlight);
      console.log("edit delete "+index);
      if (index > -1) {
          this.productHighlightList.splice(index, 1); // Remove one item at the found index
      }*/
  }

    
    deleteHighlight(highlight:any){

      const index = this.productHighlightList.indexOf(highlight);
      console.log("edit delete "+index);
      if (index > -1) {
          this.productHighlightList.splice(index, 1); // Remove one item at the found index
      }
  }


    deleteSpedification(specification: any) {
        const index = this.productSpecificationList.indexOf(specification);
        if (index > -1) {
            this.productSpecificationList.splice(index, 1); // Remove one item at the found index
        }
    }

    productSpecificationEditIndex:any=-1;
    editSpecification(specification:any){
      this.specificationType = specification.type;
      this.specificationValue = specification.value;
      console.log("beofre this.productSpecificationEditIndex "+this.productSpecificationEditIndex);
     this.productSpecificationEditIndex = this.productSpecificationList.indexOf(specification);
     console.log("after this.productSpecificationEditIndex "+this.productSpecificationEditIndex);
    }

    addSpecification(){
      if(this.productSpecificationEditIndex>-1){
        let spObj = {
          'type':this.specificationType,
          'value':this.specificationValue
      }
        this.productSpecificationList[this.productSpecificationEditIndex] = spObj;
        this.specificationType= '';
        this.specificationValue= '';
      }else{
        console.log("this.productHighlights "+this.productHighlights);
        let spObj = {
            'type':this.specificationType,
            'value':this.specificationValue
        }
        this.productSpecificationList.push(spObj);
        this.specificationType= '';
        this.specificationValue= '';
      }
       


    }

    productHighlightList:Array<any> =[];
    addToProductHightlightListFun(){
      if(this.productHighlightEditIndex>-1){
        this.productHighlightList[this.productHighlightEditIndex] = this.productHighlights;
        this.productHighlights= '';
      }else{
        console.log("this.productHighlights "+this.productHighlights);
        this.productHighlightList.push(this.productHighlights);
        this.productHighlights= '';
      }
        

    }

    saveImageChanges(){
        const uploadFormData = new FormData();
        uploadFormData.append('file', this.selectedFileVehicelReg);
        let details = {
            'id':this.itemId,
        
        }
        uploadFormData.append('details', JSON.stringify(details));

        this.productService.editImage(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Successfully editted the product image!',
                  }).then((result) => {
                    location.reload();
                  })
            }else{
                Swal.fire({
                    title: 'Error with editing the product image!',
                  }).then((result) => {
                    location.reload();
                  })
            }
        })




    }

    submit(form){
        var productTitle = form.productTitle;
        console.log(productTitle);
        var productDesciption = form.productDesciption;
        console.log(productDesciption);
        var productModel = form.productModel;

       
        
        let details = {
            'id':this.itemId,
            'status': this.itemStatus,
            'title':productTitle,
            'model':productModel,
            'shortDescription':productDesciption,
            'detailsLink':'admin/simple-product-edit/',
            'descriptionList':this.productHighlightList,
            'specifications':this.productSpecificationList,
            
           
        }

        const uploadFormData = new FormData();
        uploadFormData.append('details', JSON.stringify(details));
       this.productService.editDetails(uploadFormData).subscribe(res=>{
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Successfully editted the product!',
                  }).then((result) => {
                    location.reload();
                  })
            }else{
                Swal.fire({
                    title: 'Error with editing the product!',
                  }).then((result) => {
                    location.reload();
                  })
            }
        })
       


    }

    fileSizeInBytes:any;
    public selectedFileVehicelReg: any = File;
    onFileSelectedVehicleReg(event: any) {
      const file = event.target.files[0];
      this.fileSizeInBytes = file.size;
      console.log("this.fileSizeInBytes "+this.fileSizeInBytes);
      if(this.fileSizeInBytes>10485760){
        console.log("this.fileSizeInBytes 22 "+this.fileSizeInBytes);
        Swal.fire({
          title: 'Size of image should be less than 10240kb!',
        }).then((result) => {
        })
      }else{
        this.selectedFileVehicelReg = file;
      }
     
  
  
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Ergonomic Desk Sofa'
        }
    ]
}