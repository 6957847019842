
<app-navbar-style-one></app-navbar-style-one>

<div class="section-title">
    <h2>Add News</h2>
    
</div>

<section class="contact-area">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required minlength="3" maxlength="50" ngModel name="newsTitle" type="text" #newsTitle="ngModel" class="form-control" id="newsTitle" placeholder="News Title">
                                       
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input type="file" class="file-input" #fileUploadVehicleReg required accept=".jpg, .jpeg, .png"
                                        (change)="onFileSelectedVehicleReg($event)">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="newsDesciption" type="text" #newsDesciption="ngModel" class="form-control" id="newsDesciption" placeholder="News Desciption">
                                        
                                    </div>
                                </div>
                                <!--div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Your phone number">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                    </div>
                                </div-->
                                <!--div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control" placeholder="Write your message..."></textarea>
                                        
                                    </div>
                                </div-->
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> SAVE NEWS</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
</section>
