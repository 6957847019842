import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductListService } from 'src/app/service/product-list.service';
import { ProductServiceService } from 'src/app/service/product-service.service';

@Component({
    selector: 'app-slider-product-page',
    templateUrl: './slider-product-page.component.html',
    styleUrls: ['./slider-product-page.component.scss']
})
export class SliderProductPageComponent implements OnInit {

    constructor(private allProducts:ProductServiceService) { }

	productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: false,
		autoplay: false,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 2
			},
			576: {
				items: 3
			},
			768: {
				items: 3
			},
			992: {
				items: 4
			},
			1200: {
				items: 4
			}
		}
    }
	singleProductsItem = [];
    ngOnInit(): void {
		 this.allProducts.getAllDetails().subscribe(res=>{
			this.singleProductsItem =res;
		});
		console.log("all products "+JSON.stringify(this.singleProductsItem));
    }

	sectionTitle = [
        {
            title: "All Products",
            paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
        }
    ]




}