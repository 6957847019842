<app-navbar-style-one></app-navbar-style-one>

<section class="products-details-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Edit Product Image</h2>
            
        </div>
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <a href="{{selectedDetails.mainImg}}" class="popup-btn">
                        <img src="{{selectedDetails.mainImg}}" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="form-group mb-3">
                    <input type="file" class="file-input" #fileUploadVehicleReg required accept=".jpg, .jpeg, .png"
                    (change)="onFileSelectedVehicleReg($event)">
                </div>
                <button  (click)="saveImageChanges()" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> Save Image</button>
              
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="section-title">
                    <h2>Edit Product Details</h2>
                    
                </div>
                
                <section class="contact-area">
                    <div class="container">
                        <div class="contact-inner-area">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="contact-form">
                                        <form #contactForm="ngForm">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8">
                                                    <div class="form-group mb-3">
                                                        <input required minlength="3" maxlength="50" ngModel name="productTitle" type="text" [ngModel]="selectedDetails.title" #productTitle="ngModel" class="form-control" id="productTitle" placeholder="Product Title">
                                                       
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4">
                                                   
                                                    <!--div class="form-group mb-3">
                                                        <input type="file" class="file-input" #fileUploadVehicleReg required accept=".jpg, .jpeg, .png"
                                                        (change)="onFileSelectedVehicleReg($event)">
                                                    </div-->
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8">
                                                    <div class="form-group mb-3">
                                                        <input required ngModel name="productDesciption" type="text" #productDesciption="ngModel" [ngModel]="selectedDetails.shortDescription" class="form-control" id="productDesciption" placeholder="Product Desciption">
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group mb-3">
                                                        <input required ngModel name="productModel" type="text" #productModel="ngModel" [ngModel]="selectedDetails.model" class="form-control" id="productModel" placeholder="Product Model">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group mb-3">
                                                      <p><b>Add Product Highlight</b></p>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8">
                                                    <div class="form-group mb-3">
                                                        <input required ngModel name="productHighlight" type="text" [(ngModel)]="productHighlights" class="form-control" id="productHighlight" placeholder="Add Product Highlight">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group mb-3">
                                                        <button class="default-btn" (click)="addToProductHightlightListFun()"><i class='bx bx-plus'></i>Add To List</button>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <table class="table table-striped">
                                                        <tbody  *ngFor="let highlight of productHighlightList;">
                                                            <tr>
                                                                <td>{{highlight}}</td>
                                                                <td><button (click)="editHighlight(highlight)"><i class='bx bx-pen'></i></button></td>
                                                                <td><button (click)="deleteHighlight(highlight)"><i class='bx bx-trash'></i></button></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--ul *ngFor="let Content of productHighlightList;" >
                                                        <li [innerHTML]="Content">{{Content}}  </li>
                                                    </ul-->
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group mb-3">
                                                      <p><b>Add Product Specifications</b></p>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group mb-3">
                                                        <input required ngModel name="specificationType" type="text" [(ngModel)]="specificationType" class="form-control" id="specificationType" placeholder="Add Specification Type">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group mb-3">
                                                        <input required ngModel name="specificationValue" type="text" [(ngModel)]="specificationValue" class="form-control" id="specificationValue" placeholder="Add Specification Value">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4">
                                                    <div class="form-group mb-3">
                                                        <button  class="default-btn" (click)="addSpecification()"><i class='bx bx-plus'></i>Add Specification</button>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <table class="table table-striped">
                                                        <tbody  *ngFor="let speccification of productSpecificationList;">
                                                            <tr>
                                                                <td>{{speccification.type}}</td>
                                                                <td>: {{speccification.value}}</td>
                                                                <td><button (click)="editSpecification(speccification)"><i class='bx bx-pen'></i></button></td>
                                                                <td><button (click)="deleteSpedification(speccification)"><i class='bx bx-trash'></i></button></td>
                                                            </tr>
                                                           
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <button type="submit" (click)="submit(contactForm.value)" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane'></i> Save Changes</button>
                                                </div>
                                            </div>      
                                            
                                        </form>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <!--div class="col-lg-12 col-md-12">
                <div class="products-details-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">PRODUCT HIGHLIGHTS</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="additional-information-tab" data-bs-toggle="tab" data-bs-target="#additional-information" type="button" role="tab" aria-controls="additional-information" aria-selected="false">SPECIFICATIONS</button>
                        </li>
                       
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel">
                            <p>{{selectedDetails.description}}</p>
                            <ul *ngFor="let Content of selectedDetails.descriptionList;" >
                                <li [innerHTML]="Content">{{Content}}</li>
                            </ul>
                        </div>
                        <div class="tab-pane fade" id="additional-information" role="tabpanel">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tbody  *ngFor="let speccification of selectedDetails.specifications;">
                                        <tr>
                                            <td>{{speccification.type}}</td>
                                            <td>: {{speccification.value}}</td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div-->
        </div>
    </div>
</section>
<!-- End Products Details Area -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-C8F6CSWD62"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-C8F6CSWD62');
</script>