import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductListService } from 'src/app/service/product-list.service';
import { ProductServiceService } from 'src/app/service/product-service.service';

@Component({
    selector: 'app-simple-product-page',
    templateUrl: './simple-product-page.component.html',
    styleUrls: ['./simple-product-page.component.scss']
})
export class SimpleProductPageComponent implements OnInit {
    itemId: string;
    constructor(private route: ActivatedRoute, private itemDetails: ProductListService, private productService: ProductServiceService) { }
    title: string;
    selectedDetails: any;



    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.itemId = params['itemId'];
            console.log("product_id " + this.itemId);
            this.productService.getProductDetailsById(this.itemId).subscribe(res => {
                console.log("selectedItem " + JSON.stringify(res));
                this.selectedDetails = res;
            })


            /*const selectedItem = this.itemDetails.getDetails(this.itemId);
            this.selectedDetails = selectedItem;
            console.log("proooo "+JSON.stringify(selectedItem));*/

        });

    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Ergonomic Desk Sofa'
        }
    ]

}