<app-navbar-style-one></app-navbar-style-one>

<!--app-homethree-banner></app-homethree-banner-->

<!--app-homethree-categories></app-homethree-categories-->

<section class="products-area ptb-100 bg-f5f5f5">
    <app-upcoming-products></app-upcoming-products>
</section>

<section class="blog-area pt-50 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let Content of sectionTitle;">
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let Content of singleBlogPost;">
                <div class="single-blog-post">
                    <div class="post-image">
                        <!--<a routerLink="/{{Content.link}}" class="d-block">
                            <img [src]="Content.imageUrl" alt="image">
                        </a>-->
                        <a routerLink="/admin/edit-news/{{Content.id}}" class="d-block">
                            <img [src]="Content.imageUrl" alt="image">
                        </a>
                        

                    </div>
                    <a routerLink="/admin/edit-news/{{Content.id}}" class="d-block">
                    <div class="post-content">
                        <!--ul class="meta">
                            <li><i class="bx bx-purchase-tag"></i> <a routerLink="/{{Content.link}}">{{Content.tag}}</a></li>
                        </ul-->
                        <!--<a routerLink="/{{Content.link}}"></a>-->
                        <h3>{{Content.newsTitle}}</h3>
                        <p>{{Content.newsDesciption}}</p>
                        <!--a routerLink="/{{Content.link}}" class="link-btn">{{Content.linkText}} <i class='bx bx-right-arrow-alt'></i></a-->
                    </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!--section class="products-area ptb-100">
    <app-trending-products></app-trending-products>
</section-->

<!--app-facility-style-one></app-facility-style-one>

<section class="products-area ptb-100">
    <app-popular-products></app-popular-products>
</section>

<app-discount></app-discount>

<app-testimonials></app-testimonials>

<app-blog></app-blog>

<app-partner></app-partner-->