import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-navbar-style-one',
    templateUrl: './navbar-style-one.component.html',
    styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    logOutClicked(){
        console.log("log out clicked "+localStorage.getItem('token'));
        localStorage.setItem('token','');
        console.log("log out clicked "+localStorage.getItem('token'));
        window.location.href = '/admin/login';

    }
}