import { Component, OnInit } from '@angular/core';
import { SendContactUsMailService } from 'src/app/service/send-contact-us-mail.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    constructor(private contactus:SendContactUsMailService) { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Contact Us'
        }
    ]

    submit(form){
        var name = form.name;
        console.log(name);
        
        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);
        
        var message = form.message;
        console.log(message);
        
        let details = {
            'name':name,
            'email':email,
            'mobile':number,
            'message':message
        }
        this.contactus.saveCustomer(details).subscribe(res=>{
            console.log("send customer email "+res);
            var status = res.status;
            if(status == 'success'){
                Swal.fire({
                    title: 'Successfully sent the message!',
                  }).then((result) => {
                    location.reload();
                  })
            }else{
                Swal.fire({
                    title: 'Error with seding tne message!',
                  }).then((result) => {
                    location.reload();
                  })
            }
        })


    }

}