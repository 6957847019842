import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductListService } from 'src/app/service/product-list.service';

@Component({
    selector: 'app-sticky-product-page',
    templateUrl: './sticky-product-page.component.html',
    styleUrls: ['./sticky-product-page.component.scss']
})
export class StickyProductPageComponent implements OnInit {

    constructor(private productService:ProductListService,private route: ActivatedRoute) { }

	productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: false,
		autoplay: false,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 2
			},
			576: {
				items: 3
			},
			768: {
				items: 3
			},
			992: {
				items: 4
			},
			1200: {
				items: 4
			}
		}
    }
	singleProductsItem = [];
    searchtext:any;
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.searchtext = params['searchtext']; 
            console.log("searchtext "+this.searchtext);

            const selectedItem = this.productService.getProductsInTitle(this.searchtext);
            this.singleProductsItem = selectedItem;
            console.log("proooo "+JSON.stringify(selectedItem));

            if(this.singleProductsItem.length==0){
                
            
            }
           
          });
    }

	sectionTitle = [
        {
            title: "Search Products",
            paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
        }
    ]

}