import { Component, OnInit } from '@angular/core';
import { collection, getDocs } from "firebase/firestore";
import { ProductListService } from 'src/app/service/product-list.service';
import { ProductServiceService } from 'src/app/service/product-service.service';

@Component({
    selector: 'app-new-products',
    templateUrl: './new-products.component.html',
    styleUrls: ['./new-products.component.scss']
})
export class NewProductsComponent implements OnInit {
    singleProductsItem=[];
    constructor(private productService:ProductServiceService) {
        productService.getNewProducts().subscribe(res=>{
            this.singleProductsItem =  res;
            console.log("New Products "+JSON.stringify(res));
        })
       
     }

    ngOnInit() {

    }

    sectionTitle = [
        {
            title: "New Products",
            paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida."
        }
    ]
   /* singleProductsItem = [
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b018n_style_20150525.png?alt=media&token=763fe1ba-dab8-49b7-9478-c5f7b42cef33',
            title: 'TAMRON 18-200mm F/3.5-6.3 DI II VC ',
            newPrice: 'Model B018',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/1'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b023n_style_20170111.png?alt=media&token=12c18659-bc87-40f3-a0fd-46d3cbc7e23c',
            title: 'Tamron 10-24mm F/3.5-4.5 Di II VC HLD',
            newPrice: 'Model B023',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/2'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b028n_style_20170313.png?alt=media&token=eec95047-92d6-471d-86b1-6975be581034',
            title: 'Tamron 18-400mm F/3.5-4.5 Di II VC HLD ',
            newPrice: 'Model B028',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/3'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a010n_style_20140619.png?alt=media&token=ef281b07-d88c-4b5d-88cd-c085cb79a6ad',
            title: 'TAMRON 28-300mm F/3.5-6.3 Di VC PZD',
            newPrice: 'Model A010',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/4'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a022n_style_20160705.png?alt=media&token=c299abd9-9824-4e02-8705-2fcde81dae33',
            title: 'Tamro SP 150-600mm F/5-6.3 Di VC USD G2 ',
            newPrice: 'Model A022',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/5'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a025n_style_20170106.png?alt=media&token=6bc4d980-96b4-4756-84a6-b0e520c09f18',
            title: 'Tamron SP 70-200mm F/2.8 Di VC USD G2',
            newPrice: 'Model A025',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/6'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b060_style_8_20210226.png?alt=media&token=ae9a0d45-7fba-48e2-aa48-9feaf7dd13ab',
            title: 'TAMRON 11-20mm F/2.8 Di III-A RXD',
            newPrice: 'Model B060',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/7'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b061e_style_20210601.png?alt=media&token=650489d7-2520-4e21-ad25-34c0e310e232',
            title: 'TAMRON 18-300mm F/3.5-6.3 Di III-A VC VXD',
            newPrice: 'Model B061',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/8'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/b070_style_8_20201019.png?alt=media&token=b6954daa-73fe-4f0d-ab51-856ffd0b8314',
            title: 'Tamron 17-70mm F/2.8 Di III-A VC RXD',
            newPrice: 'Model B070',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/9'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a036_style.png?alt=media&token=9b2955e1-c189-4049-958d-a1997f6b0134',
            title: 'TAMRON 28-75mm F/2.8 Di III RXD',
            newPrice: 'Model A036',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/10'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a046s_style_20190411.png?alt=media&token=4ca6c37e-3c03-4a40-89fa-05e9737292f4',
            title: 'Tamron 70-300mm F/4.5-6.3 Di III RXD',
            newPrice: 'Model A046',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/11'
        },
        {
            mainImg: 'https://firebasestorage.googleapis.com/v0/b/tamronlanka.appspot.com/o/a047_style_8_200727.png?alt=media&token=7741a38a-e852-483c-a1a6-f38ead6c2e1a',
            title: 'Tamron 70-300mm F/4.5-6.3 Di III RXD',
            newPrice: 'Model A047',
            oldPrice: '',
            sale: '',
            outOfStock: '',
            detailsLink: 'simple-product/12'
        }
    ]*/

}